import styled from 'styled-components'

export const DocumentSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    & > .related-to-switch {
        margin-top: 16px;
    }

    > :first-child {
        margin: 1rem 0;
    }
`

export const DocumentTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 1rem;

    > :last-child {
        width: 240px;
    }
`
