import { API } from 'api'
import { requestPasswordRecoverParams } from 'api/utils/interfaces'
import { AxiosRequestConfig } from 'axios'

export default function requestPasswordRecover(
    params: requestPasswordRecoverParams,
): Promise<any> {
    const headers: AxiosRequestConfig['headers'] = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    return API.post('auth/accounts/password/reset/', params, headers)
}
