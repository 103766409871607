import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import { addUser, editUser } from 'api/users'
import { PhoneInputField } from 'apps/shared/components/FormInputFields'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import Modal from 'apps/shared/components/Modals/Modal'
import { ErrorMessage, FormLabel } from 'apps/shared/shared.styled'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import { User } from 'state/user/UserState'
import isEmail from 'validator/lib/isEmail'

import { Checkbox, ModalWrapper } from '../Modals.styled'

export default function UserModal(defaultProps: {
    onClose: () => void
    onSubmit: () => void
    preselectedUser?: User | null
}) {
    const { onClose, onSubmit, preselectedUser } = defaultProps

    const [firstName, setFirstName] = useState(
        preselectedUser?.first_name || '',
    )
    const [lastName, setLastName] = useState(preselectedUser?.last_name || '')
    const [email, setEmail] = useState(preselectedUser?.email || '')
    const [emailError, setEmailError] = useState('')

    const [phoneNumber, setPhoneNumber] = useState(
        preselectedUser?.phone_number || '',
    )
    const [phoneNumberError, setPhoneNumberError] = useState('')

    const [isAdmin, setIsAdmin] = useState(
        preselectedUser?.is_vendor_admin || false,
    )
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)
    const [canSubmit, setCanSubmit] = useState(true)
    const { user } = useUser()

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    useEffect(() => {
        let isEdited = true
        const hasErrors = emailError !== '' || phoneNumberError !== ''

        if (preselectedUser) {
            isEdited =
                preselectedUser.first_name !== firstName ||
                preselectedUser.last_name !== lastName ||
                preselectedUser.email !== email ||
                preselectedUser.phone_number !== phoneNumber ||
                preselectedUser.is_vendor_admin !== isAdmin
        }

        const requieredFieldsSet =
            firstName !== '' && lastName !== '' && email !== ''
        const isValidEmail = isEmail(email)

        setCanSubmit(
            isEdited && requieredFieldsSet && isValidEmail && !hasErrors,
        )
    }, [
        email,
        emailError,
        firstName,
        lastName,
        phoneNumberError,
        isAdmin,
        preselectedUser,
        phoneNumber,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            is_vendor_admin: isAdmin,
        }

        const apiCall = preselectedUser
            ? editUser(userData, preselectedUser.id)
            : addUser(userData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedUser
                        ? 'User has been edited successfully!'
                        : 'User has been added successfully!',
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return errorToast(`User with that email already exists.`)
                }

                if (err.response.data.phone_number) {
                    return errorToast('Invalid phone number.')
                }

                if (err.response.status !== 500) {
                    if (err.response.data.detail.includes('Email')) {
                        return setEmailError('Invalid email.')
                    }

                    if (err.response.data.detail.includes('admin')) {
                        return errorToast(
                            // eslint-disable-next-line max-len
                            'User cannot be deleted because at least one user must be an admin.',
                        )
                    }

                    return errorToast(
                        preselectedUser
                            ? 'Failed to update customer, please try again.'
                            : 'Failed to create customer, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetEmail = (input: string) => {
        setEmail(input)
        setEmailError('')

        if (input && !isEmail(input)) {
            setEmailError('Please provide a valid email.')
        } else {
            setEmailError('')
        }
    }

    return (
        <Modal
            onClose={onClose}
            title="New"
            body={
                <ModalWrapper>
                    <FormLabel>First Name</FormLabel>
                    <Form.Control
                        {...{
                            ref: inputRef,
                            type: 'text',
                            value: firstName,
                            onChange: (e: any) => setFirstName(e.target.value),
                            onBlur: (e: any) =>
                                setFirstName(e.target.value.trim()),
                        }}
                    />
                    <FormLabel>Last Name</FormLabel>
                    <Form.Control
                        {...{
                            type: 'text',
                            value: lastName,
                            onChange: (e: any) => setLastName(e.target.value),
                            onBlur: (e: any) =>
                                setLastName(e.target.value.trim()),
                        }}
                    />
                    <FormLabel>Email Address</FormLabel>
                    <Form.Control
                        {...{
                            placeholder: 'name@example.com',
                            type: 'email',
                            value: email,
                            onChange: (e: any) =>
                                handleSetEmail(e.target.value),
                        }}
                    />
                    {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                    <FormLabel>
                        Phone number<span> (optional)</span>
                    </FormLabel>
                    <PhoneInputField
                        {...{
                            phoneNumber,
                            onChange: (phoneInput, errorMessage) => {
                                setPhoneNumber(phoneInput)
                                setPhoneNumberError(errorMessage)
                            },
                        }}
                    />
                    {phoneNumberError && (
                        <ErrorMessage>{phoneNumberError}</ErrorMessage>
                    )}
                    {preselectedUser?.id !== user?.id && (
                        <Checkbox
                            id="isAdmin"
                            defaultChecked={isAdmin}
                            onChange={(e: any) => setIsAdmin(e.target.checked)}
                            label="Admin"
                        />
                    )}
                </ModalWrapper>
            }
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
        />
    )
}
