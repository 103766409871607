// eslint-disable-next-line import/no-extraneous-dependencies
import Flag from 'react-world-flags'

import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const CountryFlagContainer = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
`

export const CountryFlag = styled(Flag)`
    max-width: 30px;
    max-height: 20px;
    margin-right: 4px;
    border: 1px solid ${secondary.almostWhiteGray};
`
