export const navItemList = [
    {
        text: 'Dashboard',
        icon: 'fa-regular fa-house',
        path: '/',
    },
    {
        text: 'Subscriptions',
        icon: 'fa-regular fa-money-check-dollar-pen',
        path: '/subscriptions',
    },
    {
        text: 'Document Library',
        icon: 'fa-regular fa-folder-open',
        path: '/administration/document-library',
    },
    {
        text: 'Administration',
        icon: 'fa-regular fa-cube',
        path: '/administration',
        borderTop: true,
        subItems: [
            {
                text: 'Partners',
                icon: '',
                path: '/administration/partners',
            },
            {
                text: 'Invitations',
                icon: '',
                path: '/administration/invitations',
            },
            {
                text: 'Margin Structures',
                icon: '',
                path: '/administration/margin-structures',
            },
            {
                text: 'Customers',
                icon: '',
                path: '/administration/customers',
            },
            {
                text: 'Licenses',
                icon: '',
                path: '/administration/licenses',
            },
            {
                text: 'Articles',
                icon: '',
                path: '/administration/articles',
            },
        ],
    },
    {
        text: 'Users',
        icon: 'fa-regular fa-users',
        path: '/users',
        permissions: ['is_vendor_admin'],
    },
]
