import React from 'react'
import { Form } from 'react-bootstrap'

import { FormLabel } from 'apps/shared/shared.styled'
import {
    MissingAssetText,
    MultipleSection,
} from 'apps/vendor/components/Modals/Modals.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import {
    CustomerStatus,
    CustomerType,
} from 'apps/vendor/interfaces/subscriptions'

interface LicenseTabProps {
    handlers: {
        handleSetLicenseKey: (licenseKey: string) => void
        setName: (name: string) => void
        renderErrorMessage: (error: string) => JSX.Element | null
        onCustomerSelected: (customer: number) => void
    }
    states: {
        inputRef: React.RefObject<HTMLInputElement>
        name: string
        partnerOwnedLicense: boolean
        licenseKey: string | number
        licenseKeyError: string
        customers: CustomerType[]
        selectedCustomer: CustomerType | null
        licenseCustomer: string | null | undefined
    }
}

export default function LicenseTab(props: LicenseTabProps) {
    const { handlers, states } = props

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    ref={states.inputRef}
                    placeholder="License name"
                    value={states.name}
                    type="text"
                    onChange={(e) => handlers.setName(e.target.value)}
                    onBlur={(e) => handlers.setName(e.target.value.trim())}
                    disabled={states.partnerOwnedLicense}
                />
            </>
        )
    }

    const renderLicenseKeyField = () => {
        return (
            <>
                <FormLabel>License Key</FormLabel>
                <Form.Control
                    placeholder="License/Agreement identifier"
                    value={states.licenseKey}
                    type="text"
                    onChange={(e) =>
                        handlers.handleSetLicenseKey(e.target.value)
                    }
                    onBlur={(e) =>
                        handlers.handleSetLicenseKey(e.target.value.trim())
                    }
                    disabled={states.partnerOwnedLicense}
                />
                {!states.partnerOwnedLicense &&
                    handlers.renderErrorMessage(states.licenseKeyError)}
            </>
        )
    }

    const renderMissingAsset = (text: string) => {
        return <MissingAssetText>{text}</MissingAssetText>
    }

    const renderCustomerDropdown = () => {
        if (!states.partnerOwnedLicense && states.customers.length === 0) {
            return (
                <>
                    <FormLabel>Customer</FormLabel>
                    {renderMissingAsset(
                        'Create a customer before adding a license.',
                    )}
                </>
            )
        }

        const options = states.customers
            .filter((customer) => customer.status === CustomerStatus.COMPLETE)
            .map((customer) => ({
                label: customer.name,
                value: customer.id,
            }))

        const value = states.selectedCustomer
            ? {
                  label: states.selectedCustomer.name,
                  value: states.selectedCustomer.id,
              }
            : null

        return (
            <>
                <FormLabel>Customer</FormLabel>
                <SelectDropdown
                    options={options}
                    onChange={handlers.onCustomerSelected}
                    value={value}
                    isDisabled={
                        states.partnerOwnedLicense || options.length < 2
                    }
                    placeholder={
                        (states.partnerOwnedLicense
                            ? states.licenseCustomer
                            : states.selectedCustomer?.name) ||
                        'Choose customer'
                    }
                    isSearchable
                />
            </>
        )
    }

    return (
        <>
            <MultipleSection>
                <div>{renderNameField()}</div>
                <div>{renderLicenseKeyField()}</div>
            </MultipleSection>
            {renderCustomerDropdown()}
        </>
    )
}
