import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

import { uploadDocument } from 'api/vendors/documents'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { PartnerType } from 'apps/vendor/pages/Administration'
import useToast from 'hooks/useToast'

import DocumentSettingsSection from './components/DocumentSettingsSection'

type FileUploadModalProps = {
    customerId?: number
    partners?: PartnerType[]
    customers?: CustomerType[]
    onClose: () => void
    onUpdate: () => void
}

export default function FileUploadModal(defaultProps: FileUploadModalProps) {
    const { customerId, partners, customers, onClose, onUpdate } = defaultProps
    const { successToast, errorToast } = useToast()
    const [canSubmit, setCanSubmit] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [relatedToActive, setRelatedToActive] = useState(!!customerId)

    const [selectedCustomer, setSelectedCustomer] = useState<number | null>(
        customerId || null,
    )
    const [selectedPartner, setSelectedPartner] = useState<number | null>(null)

    const [selectedCustomRelatedTo, setSelectedCustomRelatedTo] = useState<
        string | null
    >(null)

    const [documentType, setDocumentType] = useState<string | null>(
        customerId ? 'CUSTOMER_AGREEMENT' : null,
    )

    const isOneOrNoneRelatedToSelected = () => {
        if (relatedToActive) {
            const count =
                (selectedCustomer ? 1 : 0) +
                (selectedPartner ? 1 : 0) +
                (selectedCustomRelatedTo ? 1 : 0)

            return count === 1
        }

        return !selectedCustomer && !selectedPartner && !selectedCustomRelatedTo
    }

    useEffect(() => {
        if (!file) {
            setCanSubmit(false)

            return
        }

        if (file.type !== 'application/pdf') {
            errorToast('Only PDF files are allowed')
            setCanSubmit(false)

            return
        }

        const isAbleToSubmit =
            file && documentType && isOneOrNoneRelatedToSelected()
        setCanSubmit(!!isAbleToSubmit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        file,
        documentType,
        relatedToActive,
        selectedCustomer,
        selectedPartner,
        selectedCustomRelatedTo,
        isOneOrNoneRelatedToSelected,
    ])

    const handleSubmit = () => {
        if (!canSubmit || !file || !documentType) return

        const params = {
            customer: selectedCustomer || null,
            partner: selectedPartner || null,
            document_type: documentType,
            custom_related_to: selectedCustomRelatedTo || null,
            document: file,
        }

        uploadDocument(params)
            .then(() => {
                successToast('Document uploaded successfully')
                onUpdate()
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return errorToast('Document already exists.')
                }
                if (err.response.status !== 500) {
                    return errorToast(
                        'Failed to save document, please try again.',
                    )
                }

                return errorToast(
                    'Unknown error. Please try again and ' +
                        'contact Sharlic support if error persists.',
                )
            })
    }

    const handleUpdateCustomer = (id: number) => {
        setSelectedPartner(null)
        setSelectedCustomRelatedTo(null)
        setSelectedCustomer(id)
    }

    const handleUpdatePartner = (id: number) => {
        setSelectedCustomer(null)
        setSelectedCustomRelatedTo(null)
        setSelectedPartner(id)
    }

    const handleUpdateCustomRelatedTo = (relatedTo: string) => {
        setSelectedPartner(null)
        setSelectedCustomer(null)
        setSelectedCustomRelatedTo(relatedTo)
    }

    const handleUpdateDocumentType = (type: string) => {
        setDocumentType(type)
    }

    const handleRelatedToActiveChange = (isActive: boolean) => {
        if (!isActive) {
            setSelectedPartner(null)
            setSelectedCustomer(null)
            setSelectedCustomRelatedTo(null)
        }
        setRelatedToActive(isActive)
    }

    const renderDocumentSettingsSection = () => {
        if (customerId) return null

        return (
            <DocumentSettingsSection
                customers={customers}
                partners={partners}
                onDocumentTypeChange={handleUpdateDocumentType}
                onCustomerChange={handleUpdateCustomer}
                onPartnerChange={handleUpdatePartner}
                onCustomRelatedToChange={handleUpdateCustomRelatedTo}
                onRelatedToActiveChange={handleRelatedToActiveChange}
            />
        )
    }

    const renderUploadFileInput = () => {
        return (
            <>
                <Form.Control
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFile(e.target.files?.[0] || null)
                    }}
                />
                {renderDocumentSettingsSection()}
            </>
        )
    }

    return (
        <Modal
            size="lg"
            onClose={onClose}
            title="Upload File"
            body={renderUploadFileInput()}
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
            isCentered
        />
    )
}
