import { createAsyncThunk } from '@reduxjs/toolkit'
import { userLogin } from 'api/users'
import { loginUserParams } from 'api/utils/interfaces'

import loadUser from './loadUser'

const loginUser = createAsyncThunk(
    'user/loginUser',
    async (params: loginUserParams, { dispatch }) => {
        const response = await userLogin(params)
        await dispatch(loadUser())

        return response
    },
)

export default loginUser
