import React, { useEffect, useState } from 'react'
import { Navbar } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { SharlicWhiteLogo } from 'apps/shared/components/Logo'
import { navType } from 'apps/shared/components/NavItem'
import NavList from 'apps/shared/components/NavList'
import useUser from 'hooks/useUser'
import { vendorNavItems, customerNavItems } from 'routes'

import { NavRow, SideBar, StyledToggle, TopBar } from './Navbar.styled'

export default function Navbars() {
    const [expanded, setExpanded] = useState(false)
    const { user } = useUser()
    const location = useLocation()

    useEffect(() => {
        const navRow = [].slice.call(document.getElementsByClassName('navItem'))

        navRow.forEach((item: HTMLElement) => {
            if (item.getAttribute('data-path') === location.pathname) {
                item.classList.add('active')
            } else {
                item.classList.remove('active')
            }
        })
    }, [location.pathname])

    const handleClick = (e: any) => {
        if (e.tagName === 'BUTTON') {
            const navRow = [].slice.call(
                document.getElementsByClassName('navItem'),
            )

            navRow.forEach((item: HTMLElement) => {
                if (item !== e) {
                    item.classList.remove('active')
                }
            })

            e.classList.add('active')
            if (
                window.innerWidth < 576 &&
                e.dataset.path !== '/administration'
            ) {
                setExpanded(false)
            }
        }
    }

    const getFilteredNavItems = (navList: navType[]) => {
        return navList.filter((navItem: navType) =>
            navItem.permissions
                ? navItem.permissions.every((permission: string) =>
                      user?.permissions?.includes(permission),
                  )
                : navItem,
        )
    }

    const getNavItemList = () => {
        let filteredNavItems: navType[] = []

        switch (user?.user_type) {
            case 'vendor':
                filteredNavItems = getFilteredNavItems(vendorNavItems)
                break

            case 'customer':
                filteredNavItems = getFilteredNavItems(customerNavItems)
                break
            default:
                break
        }

        return filteredNavItems
    }

    return (
        <SideBar>
            <Navbar collapseOnSelect expand="xl" expanded={expanded}>
                <StyledToggle
                    open={expanded}
                    onClick={() => setExpanded(!expanded)}
                >
                    <div />
                    <div />
                    <div />
                </StyledToggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Navbar.Brand>
                        <TopBar>
                            <SharlicWhiteLogo />
                        </TopBar>
                    </Navbar.Brand>
                    <NavRow
                        className="navRow"
                        onClick={(e) => handleClick(e.target)}
                    >
                        <NavList navItemList={getNavItemList()} />
                    </NavRow>
                </Navbar.Collapse>
            </Navbar>
        </SideBar>
    )
}
