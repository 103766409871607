/* eslint-disable no-param-reassign, max-len */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import { loadVendor } from './actions'
import VendorState from './VendorState'

const initialState = {
    data: undefined,
    status: LoadingStatus.IDLE,
    error: undefined,
}

const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        loadVendor: (state: VendorState) => {
            state.status = LoadingStatus.IDLE
        },
        updateVendor: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadVendor.pending, (state: VendorState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(loadVendor.fulfilled, (state: VendorState, action) => {
                state.status = LoadingStatus.LOADED

                const { payload } = action

                state.data = {
                    id: payload.data.id,
                    name: payload.data.name,
                    contact_email: payload.data.contact_email,
                    stripeAccountLinked: payload.data.stripe_account_linked,
                    address_line_1: payload.data.address_line_1,
                    address_line_2: payload.data.address_line_2,
                    city: payload.data.city,
                    zip_code: payload.data.zip_code,
                    country: payload.data.country,
                    state: payload.data.state,
                    phone_number: payload.data.phone_number,
                    organization_number: payload.data.organization_number,
                    tax_id: payload.data.tax_id,
                    invoice_email: payload.data.invoice_email,
                    latitude: payload.data.latitude,
                    longitude: payload.data.longitude,
                }
            })
            .addCase(loadVendor.rejected, (state: VendorState, action) => {
                state.status = LoadingStatus.FAILED
                state.error = action.error.message
            })
    },
})

export default vendorSlice
export const { updateVendor } = vendorSlice.actions
