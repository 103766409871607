import styled from 'styled-components'
import { primary } from 'utils/colors'

export const LoadingSpinner = styled.div`
    position: relative;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(-50%);
    border: 8px solid #f3f3f3;
    border-top: 8px solid ${primary.blue};
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
