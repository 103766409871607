import React from 'react'

import { CountryFlag, CountryFlagContainer } from './CountryFlagIcon.styled'

interface CountryFlagProps {
    code?: string
    label?: string
}

export default function CountryFlagIcon(defaultProps: CountryFlagProps) {
    const { code, label } = defaultProps

    return (
        <CountryFlagContainer>
            <CountryFlag code={code} />
            <span>{label}</span>
        </CountryFlagContainer>
    )
}
