import { combineReducers } from 'redux'

import countryData from './countryData'
import user from './user'
import vendor from './vendor'

const reducer = combineReducers({
    user,
    vendor,
    countryData,
})

export default reducer
