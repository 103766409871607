import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { validateToken } from 'api/tokens'
import { CompanyInformation, UserInformation } from 'api/utils/interfaces'
import { registerVendor } from 'api/vendors'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { SharlicLogo, SharlicWhiteLogo } from 'apps/shared/components/Logo'
import { StripeLinkPage } from 'apps/vendor/pages/StripeLink'
import { SharlicBubbles } from 'assets'
import useAppDispatch from 'hooks/useAppDispatch'
import useToast from 'hooks/useToast'
import { tokenFormats } from 'routes/utils'
import { loginUser } from 'state/user/actions'

import CompanyInformationForm from './Components/CompanyInformationForm'
import ConfirmationForm from './Components/ConfirmationForm'
import UserInformationForm from './Components/UserInformationForm'
import {
    RegistrationPageWrapper,
    Image,
    RegistrationButtonsContainer,
    ProgressBar,
    Footer,
    RegistrationContentContainer,
    InformationWrapper,
    ProgressCirclesContainer,
    ExpiredTokenMessage,
} from './Register.styled'

enum RegistrationSteps {
    USERINFO = 'userInfo',
    COMPANYINFO = 'companyInfo',
    REVIEWINFO = 'reviewInfo',
    REGISTRATIONCOMPLETE = 'registrationComplete',
    STRIPEONBOARD = 'stripeOnboard',
}

export default function RegisterVendorPage() {
    const location = useLocation()
    const [selectedStep, setSelectedStep] = useState(RegistrationSteps.USERINFO)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { invitationID } = useParams()
    const { errorToast } = useToast()
    const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null)
    const [started, setStarted] = useState(false)
    const invitedEmail = location.search.split('email=')[1]?.split('/')[0] || ''
    const [userInfoValid, setUserInfoValid] = useState(false)
    const [companyInfoValid, setCompanyInfoValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [userInformation, setUserInformation] = useState<UserInformation>({
        user_email: '',
        first_name: '',
        last_name: '',
        user_phone: '',
        password: '',
    })

    const [companyInformation, setCompanyInformation] =
        useState<CompanyInformation>({
            company_name: '',
            country: {
                code: '',
                name: '',
            },
            address_line_1: '',
            address_line_2: '',
            zip_code: '',
            city: '',
            state: '',
            organization_number: '',
            reference: '',
            contact_email: '',
            invoice_email: '',
            vendor_phone: '',
        })

    useEffect(() => {
        validateToken(invitationID)
            .then(() => {
                setTokenIsValid(true)
            })
            .catch(() => {
                setTokenIsValid(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (tokenIsValid === null) {
        return null
    }

    const handleSubmit = () => {
        setIsLoading(true)
        if (!invitationID?.match(tokenFormats.UUID)) {
            errorToast(
                // eslint-disable-next-line max-len
                'There was a problem with your invitation, please try again. If the problem persists, contact Sharlic.',
            )
            setIsLoading(false)

            return
        }

        const registrationParams = {
            token: invitationID,
            ...userInformation,
            ...companyInformation,
            country: companyInformation.country.code,
        }

        registerVendor(registrationParams)
            .then(() => {
                const params = {
                    email: userInformation.user_email,
                    password: userInformation.password,
                }

                dispatch(loginUser(params)).then((data) => {
                    if (data.type !== 'user/loginUser/fulfilled') {
                        errorToast(
                            `There was a problem with logging in,
                            please go to the login page and try again.`,
                        )
                    }
                })
                setSelectedStep(RegistrationSteps.REGISTRATIONCOMPLETE)
                setIsLoading(false)
            })
            .catch((error) => {
                setSelectedStep(RegistrationSteps.REVIEWINFO)
                setIsLoading(false)
                const errorData = error.response.data

                const defaultErrorMessage = `There was a problem with your
                registration. Please try again.
                If the problem persists, contact Sharlic.`

                if (errorData) {
                    const errorKeys = Object.keys(errorData)
                    if (errorKeys.length > 0) {
                        errorKeys.forEach((key) => {
                            const errorMessage = errorData[key]

                            const formattedKey =
                                key.charAt(0).toUpperCase() + key.slice(1)
                            if (Array.isArray(errorMessage)) {
                                errorToast(
                                    `${formattedKey}: ${errorMessage.join(
                                        ', ',
                                    )}`,
                                )
                            } else {
                                errorToast(`${formattedKey}: ${errorMessage}`)
                            }
                        })
                    } else {
                        errorToast(defaultErrorMessage)
                    }
                } else {
                    errorToast(defaultErrorMessage)
                }
            })
    }

    const getStepProperties = (currentStep: RegistrationSteps) => {
        let isValid = false
        let prevValid = false
        let isDisabled = false
        let isLast = false
        let previous = null
        let next = null

        switch (currentStep) {
            case RegistrationSteps.USERINFO:
                isValid = userInfoValid
                next = RegistrationSteps.COMPANYINFO
                break
            case RegistrationSteps.COMPANYINFO:
                isValid = companyInfoValid
                prevValid = userInfoValid
                isDisabled = !userInfoValid
                previous = RegistrationSteps.USERINFO
                next = RegistrationSteps.REVIEWINFO
                break
            case RegistrationSteps.REVIEWINFO:
                isValid =
                    userInfoValid &&
                    companyInfoValid &&
                    selectedStep === RegistrationSteps.REVIEWINFO
                prevValid = userInfoValid && companyInfoValid
                isDisabled = !userInfoValid || !companyInfoValid
                previous = RegistrationSteps.COMPANYINFO
                next = RegistrationSteps.STRIPEONBOARD
                isLast = true
                break
            default:
                break
        }

        return { isValid, prevValid, isDisabled, isLast, previous, next }
    }

    const getButtonClassNames = (
        currentStep: RegistrationSteps,
        isValid: boolean,
        prevValid: boolean,
    ) => {
        return [
            selectedStep === currentStep ? 'selected' : '',
            isValid ? 'valid' : '',
            prevValid ? 'prevValid' : '',
        ]
            .join(' ')
            .trim()
    }

    const getButtonProps = (currentStep: RegistrationSteps) => {
        const { isValid, prevValid, isDisabled, isLast, previous, next } =
            getStepProperties(currentStep)
        const classNames = getButtonClassNames(currentStep, isValid, prevValid)

        return {
            classNames,
            isValid,
            isDisabled,
            isLast,
            previous,
            next,
        }
    }

    const handleRegistrationStepChange = (step: RegistrationSteps) => {
        if (step === selectedStep) {
            return
        }

        setSelectedStep(step)
    }

    const renderDirectionButtons = () => {
        if (isLoading) return null

        const { previous, next, isValid, isLast } = getButtonProps(selectedStep)

        return (
            <RegistrationButtonsContainer>
                <Button
                    className="rounded"
                    type="submit"
                    variant="secondary"
                    id="back-button"
                    onClick={() =>
                        (!previous && setStarted(false)) ||
                        (previous && handleRegistrationStepChange(previous))
                    }
                >
                    Back
                </Button>
                <Button
                    className="rounded"
                    type="submit"
                    variant="primary"
                    id="next-submit-button"
                    onClick={() =>
                        !isLast
                            ? next && handleRegistrationStepChange(next)
                            : handleSubmit()
                    }
                    disabled={!isValid}
                >
                    {!isLast ? 'Next' : 'Submit'}
                </Button>
            </RegistrationButtonsContainer>
        )
    }

    const renderTermsOfUseLink = () => {
        return (
            <p>
                By clicking <strong>Start</strong>, you agree to our{' '}
                <a
                    href="https://sharlic.com/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                >
                    Terms of Use
                </a>
                .
            </p>
        )
    }

    const renderInformationContainer = () => {
        const infoText = `Welcome! We're excited to have you on board. 
        To get started, please provide some information about yourself 
        and your company.`

        const infoText2 = `You can do the mandatory Stripe 
        onboarding now or later. Let's set up your account!`

        return (
            <InformationWrapper>
                <div className="text-container">
                    <h1>Account Registration</h1>
                    <p>{infoText}</p>
                    <p>{infoText2}</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    {renderTermsOfUseLink()}
                    <Button
                        className="rounded"
                        type="submit"
                        variant="primary"
                        id="start-button"
                        onClick={() => setStarted(true)}
                    >
                        Start
                    </Button>
                </div>
            </InformationWrapper>
        )
    }

    const renderRegistrationInformation = () => {
        return (
            <RegistrationContentContainer>
                {renderInformationContainer()}
            </RegistrationContentContainer>
        )
    }

    const handleUserInformationChange = (
        userInfo: UserInformation,
        isValid: boolean,
    ) => {
        setUserInformation(userInfo)
        setUserInfoValid(isValid)
    }

    const renderUserInformationForm = () => {
        return (
            <UserInformationForm
                email={invitedEmail}
                onUserInformationChange={handleUserInformationChange}
                userInformation={userInformation}
            />
        )
    }

    const handleCompanyInformationChange = (
        companyInfo: CompanyInformation,
        isValid: boolean,
    ) => {
        setCompanyInformation(companyInfo)
        setCompanyInfoValid(isValid)
    }

    const renderCompanyInformationForm = () => {
        return (
            <CompanyInformationForm
                onCompanyInformationChange={handleCompanyInformationChange}
                contactEmail={invitedEmail}
                companyInformation={companyInformation}
            />
        )
    }

    const renderReviewInformationForm = () => {
        if (isLoading) return <LoadingSpinner />

        return (
            <ConfirmationForm
                userInformation={userInformation}
                companyInformation={companyInformation}
            />
        )
    }

    const renderStripeOnboardingContainer = () => {
        if (isLoading) return <LoadingSpinner />

        return <StripeLinkPage />
    }

    const registrationCompleteContainer = () => {
        if (isLoading) return <LoadingSpinner />

        const registrationCompleteText = `You're now registered with Sharlic!
        To use all features and to receive payouts from Sharlic,
        finish your Stripe onboarding. You can do it later from your
        dashboard if you prefer.`

        return (
            <InformationWrapper>
                <div>
                    <h1>Congratulations</h1>
                    <p>{registrationCompleteText}</p>
                </div>
                <RegistrationButtonsContainer>
                    <Button
                        className="rounded"
                        type="button"
                        variant="secondary"
                        id="submit-button"
                        onClick={() => navigate('/')}
                    >
                        Log into Sharlic
                    </Button>
                    <Button
                        className="rounded"
                        type="button"
                        variant="primary"
                        id="submit-button"
                        onClick={() =>
                            setSelectedStep(RegistrationSteps.STRIPEONBOARD)
                        }
                    >
                        Take me to Stripe
                    </Button>
                </RegistrationButtonsContainer>
            </InformationWrapper>
        )
    }

    const renderRegistrationCompleteContainer = () => {
        if (isLoading) return <LoadingSpinner />

        return registrationCompleteContainer()
    }

    const renderFunctions: { [key in RegistrationSteps]: () => JSX.Element } = {
        [RegistrationSteps.USERINFO]: renderUserInformationForm,
        [RegistrationSteps.COMPANYINFO]: renderCompanyInformationForm,
        [RegistrationSteps.REVIEWINFO]: renderReviewInformationForm,
        [RegistrationSteps.REGISTRATIONCOMPLETE]:
            renderRegistrationCompleteContainer,
        [RegistrationSteps.STRIPEONBOARD]: renderStripeOnboardingContainer,
    }

    const renderView = () => {
        const renderFunction = renderFunctions[selectedStep]

        return renderFunction ? renderFunction() : null
    }

    const renderProgressButton = (
        stepNumber: number,
        viewName: RegistrationSteps,
    ) => {
        const { classNames, isDisabled, isLast } = getButtonProps(viewName)

        return (
            <ProgressCirclesContainer>
                <button
                    type="button"
                    onClick={() => handleRegistrationStepChange(viewName)}
                    className={classNames}
                    disabled={isDisabled}
                >
                    {stepNumber}
                </button>
                {!isLast && <div className={classNames} />}
            </ProgressCirclesContainer>
        )
    }

    const renderRegistrationSteps = () => {
        return (
            <Container fluid className="p-2">
                {renderView()}
            </Container>
        )
    }

    const renderRegistrationContainer = () => {
        const duringRegistration =
            selectedStep !== RegistrationSteps.STRIPEONBOARD &&
            selectedStep !== RegistrationSteps.REGISTRATIONCOMPLETE

        return (
            <RegistrationContentContainer>
                {renderRegistrationSteps()}
                {duringRegistration && (
                    <Footer className="registration-footer">
                        {renderDirectionButtons()}
                        <ProgressBar>
                            {started && !isLoading && (
                                <ul>
                                    {renderProgressButton(
                                        1,
                                        RegistrationSteps.USERINFO,
                                    )}
                                    {renderProgressButton(
                                        2,
                                        RegistrationSteps.COMPANYINFO,
                                    )}
                                    {renderProgressButton(
                                        3,
                                        RegistrationSteps.REVIEWINFO,
                                    )}
                                </ul>
                            )}
                        </ProgressBar>
                        <p id="footer">
                            Already have an account? <a href="/">Log in</a>
                        </p>
                    </Footer>
                )}
            </RegistrationContentContainer>
        )
    }

    const renderExpiredTokenMessageContainer = () => {
        return (
            <ExpiredTokenMessage>
                Your token has expired, contact your partner for a new
                invitation.
                <br />
                <br />
                Please contact Sharlic if the problem persists.
            </ExpiredTokenMessage>
        )
    }

    const renderBeginning = () => {
        return !started
            ? renderRegistrationInformation()
            : renderRegistrationContainer()
    }

    return (
        <RegistrationPageWrapper>
            <Image>
                <SharlicWhiteLogo />
                <SharlicLogo />
                <img src={SharlicBubbles} alt="bubbles" id="bubbles" />
            </Image>
            {tokenIsValid
                ? renderBeginning()
                : renderExpiredTokenMessageContainer()}
        </RegistrationPageWrapper>
    )
}
