import React from 'react'

import { StyledSubItemButton, SubItemsContainer } from './NavSubItems.styled'

interface NavSubItemsProps {
    subItems: { text: string; path: string }[]
    onNavigate: (path: string) => void
    activePath: string
}

function NavSubItems(defaultProps: NavSubItemsProps) {
    const { subItems, onNavigate, activePath } = defaultProps

    return (
        <SubItemsContainer>
            {subItems.map((subItem) => (
                <StyledSubItemButton
                    key={subItem.path}
                    type="button"
                    onClick={() => onNavigate(subItem.path)}
                    className={`subItemNavButton ${
                        subItem.path === activePath ? 'active' : ''
                    }`}
                >
                    {subItem.text}
                </StyledSubItemButton>
            ))}
        </SubItemsContainer>
    )
}

export default NavSubItems
