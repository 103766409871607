import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { initiateTwoFactor } from 'api/users'
import patchCurrentUser from 'api/users/patchCurrentUser'
import { TextButton } from 'apps/shared/components/Buttons'
import {
    EmailInput,
    PasswordInputField,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import {
    ErrorContainer,
    ErrorMessage,
    FormLabel,
} from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import { loadUser } from 'state/user'
import isEmail from 'validator/lib/isEmail'

import { ModalWrapper } from '../Modals.styled'
import ActivateTwoFactorModal from '../TwoFactorModals/ActivateTwoFactorModal'
import InactivateTwoFactorModal from '../TwoFactorModals/InactivateTwoFactorModal'
import {
    InitializeTwoFactorModalWrapper,
    VerifyPasswordSection,
} from './ProfileModal.styled'

type ProfileModalProps = {
    onClose: () => void
    onSubmit: () => void
}

export default function ProfileModal(props: ProfileModalProps) {
    const { onClose, onSubmit } = props
    const { user } = useUser()
    const dispatch = useDispatch()
    const { errorToast, successToast } = useToast()
    const [email, setEmail] = useState(user?.email || '')
    const [emailWarning, setEmailWarning] = useState('')
    const [firstName, setFirstName] = useState(user?.first_name || '')
    const [lastName, setLastName] = useState(user?.last_name || '')
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || '')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [locale, setLocale] = useState(user?.locale || '')
    const [canSubmit, setCanSubmit] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const browserLocale = window.navigator.language
    const [password, setPassword] = useState('')
    const [token, setToken] = useState('')

    const [showPasswordVerificationInput, setShowVerificationInput] =
        useState(false)

    const [showActivateTwoFactorModal, setShowActivateTwoFactorModal] =
        useState(false)

    const [showInactivateTwoFactorModal, setShowInactivateTwoFactorModal] =
        useState(false)

    const browserLanguageName = new Intl.DisplayNames(['en'], {
        type: 'language',
    }).of(browserLocale.split('-')[0])

    const localeOptions = [
        {
            value: browserLocale,
            label: `Browser default (${browserLanguageName})`,
        },
        { value: 'en-US', label: 'English (US)' },
        { value: 'sv-SE', label: 'Swedish' },
    ]

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        if (user) {
            const formIsDirty =
                user.email !== email ||
                user.first_name !== firstName ||
                user.last_name !== lastName ||
                user.phone_number !== phoneNumber ||
                user.locale !== locale

            const isValidEmail = isEmail(email)
            const formIsValid = isValidEmail && !phoneNumberError

            const requiredFieldsSet =
                email !== '' && firstName !== '' && lastName !== ''

            setCanSubmit(requiredFieldsSet && formIsValid && formIsDirty)
        }
    }, [
        user,
        email,
        phoneNumber,
        phoneNumberError,
        firstName,
        lastName,
        locale,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const userData = {
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            locale,
        }

        patchCurrentUser(userData)
            .then(() => {
                dispatch(loadUser())
                onSubmit()
                successToast('Changes saved')
            })
            .catch((err) => {
                if (err.response.status !== 500) {
                    return errorToast(
                        'Failed to save changes, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetEmail = (value: string) => {
        setEmail(value)
    }

    const validateEmail = () => {
        const isValid = isEmail(email)
        const warning = isValid ? '' : 'Invalid email format.'

        setEmailWarning(warning)
    }

    const renderPhoneSection = () => {
        return (
            <>
                <FormLabel>
                    Phone number<span> (optional)</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber,
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneNumberError(errorMessage)
                        },
                    }}
                />
                {phoneNumberError && (
                    <ErrorContainer>
                        <ErrorMessage>{phoneNumberError}</ErrorMessage>
                    </ErrorContainer>
                )}
            </>
        )
    }

    const handleSetLocale = (localeCode: string) => {
        const localeOption = localeOptions.find(
            (option) => option.value === localeCode,
        ) || { label: '', value: '' }

        if (localeOption.label.includes('Browser default')) {
            return setLocale('')
        }

        return setLocale(localeOption.value)
    }

    const renderLocaleSection = () => {
        const formattedLocale = localeOptions.find(
            (option) => option.value === locale,
        )
        const value = formattedLocale || localeOptions[0]

        return (
            <>
                <FormLabel>Locale</FormLabel>
                <SelectDropdown
                    options={localeOptions}
                    value={value}
                    onChange={handleSetLocale}
                />
            </>
        )
    }

    const handleSubmitInitTwoFactor = async () => {
        const params = {
            email,
            password,
        }

        initiateTwoFactor(params)
            .then((response) => {
                if (response.data.token) {
                    setPassword('')
                    setToken(response.data.token)
                    setShowActivateTwoFactorModal(true)
                }
            })
            .catch(() => {
                setPassword('')
                errorToast('Failed to activate two-factor authentication')
            })
    }

    const renderEmailField = () => {
        return (
            <>
                <EmailInput
                    value={email || ''}
                    onChange={(value: string) => handleSetEmail(value)}
                    onBlur={() => {
                        validateEmail()
                    }}
                    displayIcon
                />
                {emailWarning && (
                    <ErrorContainer>
                        <ErrorMessage>{emailWarning}</ErrorMessage>
                    </ErrorContainer>
                )}
            </>
        )
    }

    const renderVerifyPasswordAndSubmit = () => {
        return (
            <VerifyPasswordSection>
                <p>Please enter your password to activate two-factor</p>
                <PasswordInputField
                    value={password}
                    onChange={setPassword}
                    onKeyDown={() => handleSubmitInitTwoFactor()}
                    required
                />
                <Button
                    className="rounded"
                    type="submit"
                    onClick={() => handleSubmitInitTwoFactor()}
                >
                    Confirm
                </Button>
            </VerifyPasswordSection>
        )
    }

    const renderTwoFactorActivatedSection = () => {
        if (!user?.is_mfa_enabled) return null

        return (
            <div>
                <p style={{ color: 'green' }}>
                    Two-factor authentication is enabled.
                </p>
                <TextButton
                    onClick={() =>
                        setShowInactivateTwoFactorModal(
                            !showInactivateTwoFactorModal,
                        )
                    }
                    text="Inactivate 2FA"
                />
            </div>
        )
    }

    const renderActivateTwoFactorAuth = () => {
        if (user?.is_mfa_enabled) return null

        return (
            <InitializeTwoFactorModalWrapper>
                <p>
                    We recommend that you activate 2FA to keep your account more
                    secure
                </p>
                <TextButton
                    onClick={() =>
                        setShowVerificationInput(!showPasswordVerificationInput)
                    }
                    text={
                        showPasswordVerificationInput
                            ? 'Cancel'
                            : 'Activate 2FA'
                    }
                />
                {showPasswordVerificationInput &&
                    renderVerifyPasswordAndSubmit()}
            </InitializeTwoFactorModalWrapper>
        )
    }

    const renderTwoFactorSection = () => {
        return (
            <>
                <FormLabel>Two factor</FormLabel>
                {renderTwoFactorActivatedSection()}
                {renderActivateTwoFactorAuth()}
            </>
        )
    }

    const renderBody = () => {
        return (
            <ModalWrapper>
                <FormLabel>First Name</FormLabel>
                <Form.Control
                    ref={inputRef}
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onBlur={(e) => setFirstName(e.target.value.trim())}
                />
                <FormLabel>Last Name</FormLabel>
                <Form.Control
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={(e) => setLastName(e.target.value.trim())}
                />
                <FormLabel>Email</FormLabel>
                {renderEmailField()}
                {renderPhoneSection()}
                {renderLocaleSection()}
                {renderTwoFactorSection()}
            </ModalWrapper>
        )
    }

    return (
        <>
            {!showActivateTwoFactorModal && !showInactivateTwoFactorModal && (
                <Modal
                    onClose={onClose}
                    title="Profile"
                    body={renderBody()}
                    footer={
                        <CancelSubmitFooter
                            onClose={onClose}
                            onSubmit={handleSubmit}
                            canSubmit={canSubmit}
                        />
                    }
                />
            )}
            {showActivateTwoFactorModal && token && (
                <ActivateTwoFactorModal
                    token={token}
                    onClose={() => {
                        setShowActivateTwoFactorModal(false)
                        setShowVerificationInput(false)
                    }}
                />
            )}
            {showInactivateTwoFactorModal && (
                <InactivateTwoFactorModal
                    onClose={() => setShowInactivateTwoFactorModal(false)}
                />
            )}
        </>
    )
}
