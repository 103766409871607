import styled from 'styled-components'

export const InitializeTwoFactorModalWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    gap: 0px;

    > p {
        font-style: italic;
        font-size: 0.9rem;
    }
`

export const VerifyPasswordSection = styled.div`
    gap: 10px;

    > Button {
        margin-top: 10px;
    }
`
