import React, { useEffect, useState } from 'react'

import { getCustomerDocuments } from 'api/customers/documents'
import { deleteDocument } from 'api/vendors'
import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { DeleteButton } from 'apps/shared/components/Buttons'
import PdfButton from 'apps/shared/components/Buttons/PdfButton'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { CustomerDocumentType } from 'apps/vendor/interfaces/documents'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

import { TableWrapper } from '../Tables.styled'
import { DocumentOptionsWrapper } from './CustomerDocumentsTable.styled'

type CustomerDocumentsTableProps = {
    customerId: number
    documents: CustomerDocumentType[]
    refreshDocuments: boolean
}

export default function CustomerDocumentsTable(
    defaultProps: CustomerDocumentsTableProps,
) {
    const { customerId, documents, refreshDocuments } = defaultProps
    const { errorToast, successToast } = useToast()
    const { user } = useUser()

    const [customerDocuments, setCustomerDocuments] = useState<
        CustomerDocumentType[]
    >(documents || [])
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedDocument, setSelectedDocument] =
        useState<CustomerDocumentType | null>()

    useEffect(() => {
        getCustomerDocuments(customerId)
            .then((res: any) => {
                if (res.data !== customerDocuments) {
                    setCustomerDocuments(res.data.reverse())
                }
            })
            .catch(() => {
                errorToast('Failed to fetch invoices')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshDocuments])

    const getHeaders = () => ['Name', 'Uploaded at', '']

    const handleOpenPdf = (document: CustomerDocumentType) => {
        window.location.href = document.document_url
    }

    const handleDeleteDocument = () => {
        if (!selectedDocument) return

        deleteDocument(selectedDocument.id)
            .then(() => {
                successToast('Document deleted successfully')
                setSelectedDocument(null)
                setShowDeleteModal(false)

                setCustomerDocuments(
                    customerDocuments.filter(
                        (doc) => doc.id !== selectedDocument.id,
                    ),
                )
            })
            .catch(() => {
                errorToast('Failed to delete document')
            })
    }

    const handleDeleteModal = (document: CustomerDocumentType) => {
        setShowDeleteModal(true)
        setSelectedDocument(document)
    }

    const getData = () => {
        if (!customerDocuments) return []

        return customerDocuments.map((document: CustomerDocumentType) => {
            return {
                uploaded_at: formatDate(
                    new Date(document.uploaded_at),
                    user?.locale,
                ),
                name: document.name,
                options: (
                    <DocumentOptionsWrapper>
                        <PdfButton onClick={() => handleOpenPdf(document)} />
                        <DeleteButton
                            onClick={() => handleDeleteModal(document)}
                        />
                    </DocumentOptionsWrapper>
                ),
            }
        })
    }

    const renderConfirmModalBody = () => {
        return (
            <div>
                <p>This action is irreversible</p>
            </div>
        )
    }

    const getAccessors = () => ['name', 'uploaded_at', 'options']

    return customerDocuments?.length === 0 ? (
        <EmptyListIcon />
    ) : (
        <TableWrapper numberOfColumns={3}>
            <BootstrapTable
                headers={getHeaders()}
                data={getData()}
                accessors={getAccessors()}
            />
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedDocument(null)
                    }}
                    onSubmit={() => handleDeleteDocument()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                />
            )}
        </TableWrapper>
    )
}
