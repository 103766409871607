import axios, { AxiosRequestConfig } from 'axios'

export class API {
    static apiRequest(
        path: string,
        method: string,
        payload?: unknown,
        headers?: AxiosRequestConfig['headers'],
    ) {
        const { REACT_APP_BACKEND_URL } = process.env
        const API_BASE_URL = `${REACT_APP_BACKEND_URL}/api/v3/`

        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.withCredentials = true
        axios.defaults.withXSRFToken = true

        return axios
            .request({
                url: path,
                method,
                headers,
                data: payload,
                baseURL: API_BASE_URL,
            })
            .then((response) => {
                const { data, status, statusText } = response

                return { data, status, statusText }
            })
            .catch((err) => {
                throw err
            })
    }

    static get(path: string) {
        return this.apiRequest(path, 'GET')
    }

    static post(
        path: string,
        payload: unknown,
        headers?: AxiosRequestConfig['headers'],
    ) {
        return this.apiRequest(path, 'POST', payload, headers)
    }

    static put(path: string, payload: unknown) {
        return this.apiRequest(path, 'PUT', payload)
    }

    static patch(path: string, payload: unknown) {
        return this.apiRequest(path, 'PATCH', payload)
    }

    static delete(path: string, payload?: unknown) {
        return this.apiRequest(path, 'DELETE', payload)
    }
}
