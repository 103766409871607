import { API } from 'api'
import { DocumentApiParams } from 'api/utils/interfaces'

export default function uploadDocument(
    params: DocumentApiParams,
): Promise<any> {
    const formData = new FormData()

    // Append other params to formData
    ;(Object.keys(params) as (keyof DocumentApiParams)[]).forEach((key) => {
        formData.append(key, params[key] as string)
    })

    return API.post(`/vendors/documents/`, formData, {
        'Content-Type': 'multipart/form-data',
    })
}
