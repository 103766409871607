import { Form } from 'react-bootstrap'

import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const ResetPasswordForm = styled.div`
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        font-size: 1.5em;
    }
    img {
        width: 50vw;
        margin: 25px;
    }
    @media screen and (min-width: 1400px) {
        .modify-password-component {
            img {
                margin: -10% 25px 32px 25px;
                width: 80%;
            }
            position: absolute;
            top: calc(50vh - 220px);
            left: calc(50vw - 200px);
            width: 400px;
            padding: 3em;
            border-radius: 10px;
            box-shadow: 0 2px 4px ${secondary.lightGray};
        }
    }
`

export const PasswordInputWrapper = styled(Form.Group)`
    > * {
        margin-bottom: 0.5rem;
    }
    > button {
        width: 100%;
        margin-top: 24px;
    }
`

export const HeaderAndDescriptionWrapper = styled.div`
    .description {
        font-size: 0.8rem;
        font-style: italic;
    }
`

export const ResetSuccessWrapper = styled.div`
    text-align: center;
    > a {
        align-items: center;
    }
`
