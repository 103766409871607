import React, { useEffect, useState } from 'react'

import { CountryFlagIcon } from 'apps/shared/components/Icons/CountryFlag'
import { useCountryData } from 'hooks'
import {
    CountryInformation,
    TaxDetail,
} from 'state/countryData/CountryDataState'

export type ParsedTaxDetail = {
    parsedTaxType: string
    taxType?: string
    example: string
    regex?: RegExp
    countryFlag?: React.ReactNode
    tax_id?: string
    country?: string
}

export const formatDisplayVat = (taxType: string, taxId: string) => {
    if (taxType === 'eu_vat') {
        const country = taxId.substring(0, 2)

        return `${country.toLowerCase()}_vat`
    }

    return taxType
}

export const useParseTaxDetails = () => {
    const { countryData } = useCountryData()
    const [taxDetails, setTaxDetails] = useState<ParsedTaxDetail[]>([])

    useEffect(() => {
        if (countryData) {
            const allTaxDetails: ParsedTaxDetail[] = []

            Object.entries(countryData.country_data).forEach(
                ([countryCode, countryInfo]: [string, CountryInformation]) => {
                    countryInfo.tax_details.forEach((taxDetail: TaxDetail) => {
                        const parsedTaxType = formatDisplayVat(
                            taxDetail.tax_type,
                            taxDetail.example,
                        )

                        const detail = {
                            parsedTaxType,
                            taxType: taxDetail.tax_type,
                            example: taxDetail.example || '',
                            country: countryCode,
                            regex: taxDetail.regex,
                            countryFlag: (
                                <CountryFlagIcon
                                    code={countryCode}
                                    label={parsedTaxType.toUpperCase()}
                                />
                            ),
                        }

                        allTaxDetails.push(detail)
                    })
                },
            )

            setTaxDetails(allTaxDetails)
        }
    }, [countryData])

    return taxDetails
}
