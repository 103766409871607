import React, { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { userLogin } from 'api/users'
import { ErrorBadge } from 'apps/shared/components/Badges'
import { SharlicLogo } from 'apps/shared/components/Logo'
import { ErrorMessage } from 'apps/shared/shared.styled'
import isEmail from 'validator/lib/isEmail'

import { Banner, LoginForm, ShowPassButton } from './Login.styled'

export default function LoginPage() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [allFieldsDirty, setAllFieldsDirty] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (isEmail(email)) {
            setInvalidEmail(false)
        } else {
            setInvalidEmail(true)
        }
    }, [email])

    useEffect(() => {
        setAllFieldsDirty(email !== '' && password !== '')
        setInvalidCredentials(false)
    }, [email, password])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitAttempted(true)

        const params = {
            email,
            password,
        }

        if (invalidEmail) {
            return
        }

        userLogin(params)
            .then((response) => {
                if (response.redirect_url) {
                    window.location.href = response.redirect_url
                } else {
                    navigate(0)
                }
            })
            .catch(() => {
                setInvalidCredentials(true)
            })
    }

    const toggleShowPassword = () => {
        const passwordField = document.querySelector('#formPassword')

        const type =
            passwordField?.getAttribute('type') === 'password'
                ? 'test'
                : 'password'

        passwordField?.setAttribute('type', type)
        setShowPassword(!showPassword)
    }

    return (
        <LoginForm>
            {/* eslint-disable-next-line max-len */}
            <Container>
                <div className="login-component">
                    <Banner>
                        <SharlicLogo />
                        <Container>
                            <h2>Log in to your account</h2>
                        </Container>
                    </Banner>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        {invalidEmail && submitAttempted && (
                            <ErrorMessage>Invalid email address</ErrorMessage>
                        )}
                        <Form.Group controlId="formPassword" className="mt-3">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ShowPassButton
                                type="button"
                                className={
                                    !showPassword
                                        ? 'far fa-eye'
                                        : 'far fa-eye-slash'
                                }
                                onClick={() => toggleShowPassword()}
                            />
                        </Form.Group>
                        {invalidCredentials &&
                            allFieldsDirty &&
                            ErrorBadge('Incorrect email or password.')}
                        <Form.Group id="submit-and-recover">
                            <a href="#/recover">Forgot password?</a>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={
                                    !allFieldsDirty ||
                                    (invalidEmail && submitAttempted) ||
                                    (invalidCredentials && invalidEmail)
                                }
                            >
                                Log in
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Container>
        </LoginForm>
    )
}
