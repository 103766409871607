import styled from 'styled-components'

export const TwoFactorAuthForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .login-component {
        margin: auto;
        img {
            width: 60%;
        }

        .textInfo {
            text-align: center;
            font-weight: bold;
        }
    }

    #submit-and-recover {
        display: flex;
        justify-content: center;
        margin-top: 2rem !important;
    }
`
