import React, { useState } from 'react'

import { TextInputField } from 'apps/shared/components/FormInputFields'
import { FormLabel, Switch } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { PartnerType } from 'apps/vendor/pages/Administration'

import { MultipleSection } from '../../Modals.styled'
import {
    DocumentSettingsWrapper,
    DocumentTypeWrapper,
} from './DocumentSettingsSection.styled'

enum RelatedToTypes {
    CUSTOMER = 'Customer',
    PARTNER = 'Partner',
    CUSTOM = 'Custom',
}

type DocumentSettingsSectionProps = {
    customers?: CustomerType[]
    partners?: PartnerType[]
    onDocumentTypeChange: (type: string) => void
    onCustomerChange: (customerId: number) => void
    onPartnerChange: (partnerId: number) => void
    onCustomRelatedToChange: (customRelatedTo: string) => void
    onRelatedToActiveChange: (relatedToActive: boolean) => void
}

export default function DocumentSettingsSection(
    defaultProps: DocumentSettingsSectionProps,
) {
    const {
        customers,
        partners,
        onDocumentTypeChange,
        onCustomerChange,
        onPartnerChange,
        onCustomRelatedToChange,
        onRelatedToActiveChange,
    } = defaultProps

    const [selectedCustomer, setSelectedCustomer] =
        useState<CustomerType | null>(null)

    const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
        null,
    )
    const [customRelatedTo, setCustomRelatedTo] = useState<string | null>(null)
    const [documentType, setDocumentType] = useState<string | null>(null)

    const [relatedToType, setRelatedToType] = useState<RelatedToTypes>(
        RelatedToTypes.CUSTOM,
    )

    const [relatedToActive, setRelatedToActive] = useState(false)

    const handleSetSelectedCustomer = (id: number) => {
        setSelectedPartner(null)
        setCustomRelatedTo(null)
        if (customers) {
            setSelectedCustomer(customers.find((obj) => obj.id === id) || null)
            onCustomerChange(id)
        }
    }

    const renderCustomerDropdown = () => {
        if (!customers) return null

        const options = customers.map((customer) => ({
            label: customer.name,
            value: customer.id,
        }))

        const selectedOption = customers.find(
            (customer) => customer.id === selectedCustomer?.id,
        )

        const value = selectedOption
            ? {
                  label: selectedOption.name,
                  value: selectedOption.id,
              }
            : null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetSelectedCustomer}
                isDisabled={!relatedToActive}
            />
        )
    }

    const handleSetSelectedPartner = (id: number) => {
        setSelectedCustomer(null)
        setCustomRelatedTo(null)
        if (partners) {
            setSelectedPartner(partners.find((obj) => obj.id === id) || null)
            onPartnerChange(id)
        }
    }

    const renderPartnerDropdown = () => {
        if (!partners) return null

        const options = partners.map((partner) => ({
            label: partner.partner_name,
            value: partner.id,
        }))

        const selectedOption = partners.find(
            (partner) => partner.id === selectedPartner?.id,
        )

        const value = selectedOption
            ? {
                  label: selectedOption.partner_name,
                  value: selectedOption.id,
              }
            : null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetSelectedPartner}
                isDisabled={!relatedToActive}
            />
        )
    }

    const handleSetDocumentType = (option: string) => {
        setDocumentType(option)
        onDocumentTypeChange(option)
    }

    const renderDocumentTypeDropdown = () => {
        const options = [
            { value: 'CUSTOMER_AGREEMENT', label: 'Customer agreement' },
            { value: 'SUPPLIER_AGREEMENT', label: 'Supplier agreement' },
            { value: 'SUPPLIER_PRICE_LIST', label: 'Supplier price list' },
            { value: 'OTHER', label: 'Other' },
        ]

        const selectedOption = options.find(
            (option) => option.value === documentType,
        )

        const value = selectedOption || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetDocumentType}
                placeholder="Select"
                isSearchable
            />
        )
    }

    const renderDocumentTypeSection = () => {
        return (
            <DocumentTypeWrapper>
                <FormLabel>Document Type:</FormLabel>
                {renderDocumentTypeDropdown()}
            </DocumentTypeWrapper>
        )
    }

    const handleSetRelatedToType = (option: RelatedToTypes) => {
        setRelatedToType(option)
    }

    const renderRelatedToDropdown = () => {
        const options = Object.values(RelatedToTypes).map((type) => ({
            label: type,
            value: type,
        }))

        const selectedOption = options.find(
            (option) => option.value === relatedToType,
        )

        const value = selectedOption || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetRelatedToType}
                placeholder="Select"
                isSearchable
                isDisabled={!relatedToActive}
            />
        )
    }

    const handleSetCustomRelatedTo = (option: string) => {
        setSelectedPartner(null)
        setSelectedCustomer(null)
        setCustomRelatedTo(option)
        onCustomRelatedToChange(option)
    }

    const handleRelatedToActive = () => {
        setRelatedToActive(!relatedToActive)
        onRelatedToActiveChange(!relatedToActive)
    }

    const renderrelatedToTypeDropdown = () => {
        return (
            <>
                {relatedToType === RelatedToTypes.CUSTOMER &&
                    renderCustomerDropdown()}
                {relatedToType === RelatedToTypes.PARTNER &&
                    renderPartnerDropdown()}
                {relatedToType === RelatedToTypes.CUSTOM && (
                    <TextInputField
                        placeholder="Enter your text here..."
                        value={customRelatedTo || ''}
                        onChange={(e) => {
                            handleSetCustomRelatedTo(e)
                        }}
                        disabled={!relatedToActive}
                    />
                )}
            </>
        )
    }

    const renderRelatedToSection = () => {
        return (
            <div>
                <Switch
                    className="related-to-switch"
                    id="related-to-switch"
                    checked={relatedToActive}
                    label="Related to"
                    onChange={() => handleRelatedToActive()}
                />
                <MultipleSection fractions={['160px', 'auto']}>
                    {renderRelatedToDropdown()}
                    {renderrelatedToTypeDropdown()}
                </MultipleSection>
            </div>
        )
    }

    return (
        <DocumentSettingsWrapper>
            {renderDocumentTypeSection()}
            {renderRelatedToSection()}
        </DocumentSettingsWrapper>
    )
}
