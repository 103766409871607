import React, { useEffect, useState } from 'react'

import { deleteArticle, getArticles } from 'api/articles'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { KebabButton } from 'apps/shared/components/Buttons'
import TextButton from 'apps/shared/components/Buttons/TextButton'
import { EmptyListIcon, BooleanIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import NewItemModalButton from 'apps/shared/components/NewItemModalButton'
import { ArticleModal } from 'apps/vendor/components/Modals/'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import { localeNumber } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

export type ArticleType = {
    id: number
    external_id: string
    name: string
    description: string
    msrp: number | string
    currency: string
    available_for_partners: boolean
    vendor: number
    related_licenses?: { id: number; name: string; vendor: string }[]
}

export default function ArticlesPage() {
    const [articles, setArticles] = useState<ArticleType[] | []>([])
    const [showArticleModal, setShowArticleModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(
        null,
    )
    const { user } = useUser()
    const { successToast, errorToast } = useToast()

    const updateArticlesList = () => {
        getArticles().then((response) => {
            setArticles(response.data)
        })
    }

    useEffect(() => {
        updateArticlesList()
    }, [])

    const onArticleModalSubmit = () => {
        updateArticlesList()
        setShowArticleModal(false)
        setSelectedArticle(null)
    }

    const handleEditArticle = (article: ArticleType) => {
        setSelectedArticle(article)
        setShowArticleModal(!showArticleModal)
    }

    const handleToggleDeleteModal = (article: ArticleType) => {
        setSelectedArticle(article)
        setShowDeleteModal(!showDeleteModal)
    }

    const handleDeleteArticle = () => {
        if (selectedArticle) {
            deleteArticle(selectedArticle.id)
                .then(() => {
                    updateArticlesList()
                    setSelectedArticle(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Article deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const getAccordionHeaders = (article: ArticleType) => {
        return (
            <BadgeRow numberOfColumns={4} rightJustifiedColumns={[2]}>
                <TextButton
                    onClick={() => handleEditArticle(article)}
                    text={article.name}
                />
                <span>
                    {localeNumber(Number(article.msrp), {
                        locale: user?.locale,
                    })}
                </span>
                <span>{article.currency}</span>
                <span>{article.external_id}</span>
            </BadgeRow>
        )
    }

    const getAccordionBody = (article: ArticleType) => {
        return (
            <AccordionBody>
                <div>
                    <p>Description</p>
                    <p>{article.description || '-'}</p>
                </div>
                <div>
                    <p className="centered">Available for partners</p>
                    <p className="centered">
                        <BooleanIcon checked={article.available_for_partners} />
                    </p>
                </div>
                <KebabButton
                    onEdit={() => handleEditArticle(article)}
                    onDelete={() => handleToggleDeleteModal(article)}
                />
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!articles) return null

        if (articles.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={4} rightJustifiedColumns={[2]}>
                    <span>Name</span>
                    <span>MSRP</span>
                    <span>Currency</span>
                    <span>Article number</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {articles.map((article, index) => (
                        <AccordionItem
                            key={article.external_id}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(article)}
                            body={getAccordionBody(article)}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This article cannot be deleted because it is currently in
                    use by the following licenses:
                </p>
                <ul>
                    {selectedArticle?.related_licenses?.map((license) => (
                        <li key={license.name}>
                            <b>{license.name}</b> owned by{' '}
                            <b>{license.vendor}</b>
                        </li>
                    ))}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to to delete the following article:
                    <p>
                        <b>{selectedArticle?.name}</b>?
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const ArticleDeletable = () => {
        return selectedArticle?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!ArticleDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <NewItemModalButton
                onButtonClicked={() => setShowArticleModal(!showArticleModal)}
            />
            {showArticleModal && (
                <ArticleModal
                    onClose={() => {
                        setShowArticleModal(!showArticleModal)
                        setSelectedArticle(null)
                    }}
                    onSubmit={() => onArticleModalSubmit()}
                    preselectedArticle={selectedArticle}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedArticle(null)
                    }}
                    onSubmit={() => handleDeleteArticle()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={ArticleDeletable()}
                />
            )}
            {renderTable()}
        </div>
    )
}
