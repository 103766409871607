import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const Banner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;

    > * {
        margin-bottom: 2rem;
    }
`

export const ShowPassButton = styled.button`
    height: 2em;
    position: relative;
    top: -2.2em;
    left: calc(100% - 2.4em);
    border: none;
    background: transparent;
    color: ${primary.gray};
`

export const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 1.5em;
    }

    #submit-and-recover {
        display: flex;
        flex-direction: column;

        a {
            justify-self: start;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-height: 1400px) {
        .login-component {
            margin: auto;
            img {
                width: 60%;
            }
        }
    }

    @media screen and (min-width: 1400px) {
        .login-component {
            img {
                margin-top: -10%;
                width: 80%;
            }

            position: absolute;
            top: calc(50vh - 220px);
            left: calc(50vw - 200px);
            width: 400px;
            padding: 3em;

            border: 1px solid ${secondary.lightGray};
            border-radius: 10px;
            box-shadow: 0 0 64px rgba(0, 0, 0, 0.05),
                0 0 16px rgba(0, 0, 0, 0.05);
        }
    }
`
