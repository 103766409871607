import { useEffect } from 'react'

import { loadCountryData } from 'state/countryData'
import LoadingStatus from 'state/enums/LoadingStatus'

import { UseCountryDataInterface } from './types/useCountryData.types'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

const useCountryData = () => {
    const dispatch = useAppDispatch()
    const countryData = useAppSelector((state) => state.countryData.data)
    const status = useAppSelector((state) => state.countryData.status)

    useEffect(() => {
        if (status === LoadingStatus.IDLE) {
            dispatch(loadCountryData())
        }
    }, [status, dispatch])

    return { countryData, status } as UseCountryDataInterface
}

export default useCountryData
