import React from 'react'

import { PdfButtonWrapper } from './PdfButton.styled'

type PdfButtonProps = {
    onClick: () => void
    text?: string
}

export default function PdfButton(defaultProps: PdfButtonProps) {
    const { onClick, text } = defaultProps
    const buttonText = text || 'Download'

    return (
        <PdfButtonWrapper
            type="button"
            onClick={() => onClick()}
            aria-label="Open PDF"
        >
            <i className="fa-solid fa-file-pdf fa-lg pdf-icon" />
            {buttonText}
        </PdfButtonWrapper>
    )
}
