import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'

import { addArticle, editArticle } from 'api/articles'
import { NumberInput } from 'apps/shared/components/FormInputFields'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import Modal from 'apps/shared/components/Modals/Modal'
import { ErrorMessage, FormLabel } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { currencyOptions } from 'apps/vendor/constants'
import { Currency } from 'apps/vendor/interfaces/subscriptions'
import { ArticleType } from 'apps/vendor/pages/Administration/Articles'
import useToast from 'hooks/useToast'

import { ModalWrapper, Checkbox } from '../Modals.styled'

interface ArticleModalProps {
    onClose: () => void
    onSubmit: () => void
    preselectedArticle?: ArticleType | null
}

export default function ArticleModal(defaultProps: ArticleModalProps) {
    const { onClose, onSubmit, preselectedArticle } = defaultProps

    const [externalId, setExternalId] = useState(
        preselectedArticle?.external_id || '',
    )
    const [externalIdError, setExternalIdError] = useState('')
    const [nameError, setNameError] = useState('')
    const [name, setName] = useState(preselectedArticle?.name || '')

    const [description, setDescription] = useState(
        preselectedArticle?.description || '',
    )
    const [msrp, setMsrp] = useState(preselectedArticle?.msrp.toString() || '')
    const [msrpError, setMsrpError] = useState('')

    const [currency, setCurrency] = useState(
        preselectedArticle?.currency || Currency.SEK,
    )

    const [availableForPartners, setAvailableForPartners] = useState(
        preselectedArticle?.available_for_partners || false,
    )
    const [canSubmit, setCanSubmit] = useState(false)
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        const isEdited = preselectedArticle
            ? externalId !== preselectedArticle.external_id ||
              name !== preselectedArticle.name ||
              description !== preselectedArticle.description ||
              msrp !== preselectedArticle.msrp ||
              currency !== preselectedArticle.currency ||
              availableForPartners !== preselectedArticle.available_for_partners
            : true

        const hasErrors =
            msrpError !== '' || externalIdError !== '' || nameError !== ''

        const requiredFieldsSet =
            externalId !== '' && name !== '' && msrp !== '' && currency !== ''

        setCanSubmit(requiredFieldsSet && isEdited && !hasErrors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        externalId,
        name,
        msrp,
        currency,
        description,
        availableForPartners,
        externalIdError,
        msrpError,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const articleData = {
            name,
            description,
            external_id: externalId,
            msrp,
            currency,
            available_for_partners: availableForPartners,
        }

        const apiCall = preselectedArticle
            ? editArticle(articleData, preselectedArticle.id)
            : addArticle(articleData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedArticle
                        ? `Article successfully edited.`
                        : `Article successfully added.`,
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    if (err.response.data.detail.includes('currency')) {
                        return errorToast(
                            // eslint-disable-next-line max-len
                            'Cannot change currency of an article that is in use.',
                        )
                    }
                }
                if (err.response.status !== 500) {
                    return errorToast(
                        preselectedArticle
                            ? 'Failed to edit article, please try again.'
                            : 'Failed to add article, please try again.',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const validateMsrp = (value: string) => {
        const splitValue = value.split('.')
        if (splitValue[1]?.length > 2) {
            setMsrpError('Max two decimals allowed.')
        } else if (value.endsWith('.')) {
            setMsrpError('Do not end on a decimal point.')
        } else if (splitValue[0]?.length > 7) {
            setMsrpError('Max seven digits before decimal.')
        } else {
            setMsrpError('')
        }
    }

    const handleSetMsrp = (value: string) => {
        validateMsrp(value)
        setMsrp(value)
    }

    const handleSetExternalId = (value: string) => {
        setExternalIdError('')
        setExternalId(value)
    }

    const handleSetName = (value: string) => {
        if (value.length > 80) {
            setNameError('Name can not exceed 80 characters.')

            return
        }
        setNameError('')
        setName(value)
    }

    const renderArticleIdField = () => {
        return (
            <>
                <FormLabel>Article ID</FormLabel>
                <Form.Control
                    ref={inputRef}
                    placeholder="Article number / Reference ID"
                    value={externalId}
                    type="text"
                    onChange={(e: any) => handleSetExternalId(e.target.value)}
                    onBlur={(e: any) =>
                        handleSetExternalId(e.target.value.trim())
                    }
                />
                {externalIdError && (
                    <ErrorMessage>{externalIdError}</ErrorMessage>
                )}
            </>
        )
    }

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    value={name}
                    type="text"
                    onChange={(e: any) => handleSetName(e.target.value)}
                    onBlur={(e: any) => handleSetName(e.target.value.trim())}
                />
                {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
            </>
        )
    }

    const renderDescriptionField = () => {
        return (
            <>
                <FormLabel>
                    Description<span> (optional)</span>
                </FormLabel>
                <Form.Control
                    value={description}
                    type="text"
                    onChange={(e: any) => setDescription(e.target.value)}
                    onBlur={(e: any) => setDescription(e.target.value.trim())}
                />
            </>
        )
    }

    const renderMsrpField = () => {
        return (
            <>
                <FormLabel>MSRP</FormLabel>
                <NumberInput
                    value={msrp}
                    onChange={handleSetMsrp}
                    placeholder="0.00"
                    config={{
                        min: 0,
                        max: 99999999,
                        allowDecimal: {
                            maxDecimalPlaces: 3,
                        },
                        allowLeadingZero: false,
                    }}
                />
                {msrpError && <ErrorMessage>{msrpError}</ErrorMessage>}
            </>
        )
    }

    const renderCurrencyDropdown = () => {
        const options = currencyOptions.map((option) => ({
            value: option.value,
            label: option.description,
        }))

        const selectedOption = options.find(
            (option) => option.value === currency,
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Currency</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={(e: any) => setCurrency(e)}
                    isSearchable
                />
            </>
        )
    }

    const renderAvailableForPartners = () => {
        return (
            <Checkbox
                {...{
                    id: 'available-for-partners',
                    name: 'available-for-partners-check',
                    label: 'Available for partners',
                    defaultChecked: availableForPartners,
                    onChange: () =>
                        setAvailableForPartners(!availableForPartners),
                }}
            />
        )
    }

    return (
        <Modal
            onClose={onClose}
            title={preselectedArticle ? 'Edit' : 'New'}
            body={
                <ModalWrapper>
                    {renderArticleIdField()}
                    {renderNameField()}
                    {renderDescriptionField()}
                    {renderMsrpField()}
                    {renderCurrencyDropdown()}
                    {renderAvailableForPartners()}
                </ModalWrapper>
            }
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    canSubmit={canSubmit}
                />
            }
        />
    )
}
