import React from 'react'

import {
    InvitationsPage,
    PartnersPage,
    MarginStructuresPage,
    CustomersPage,
    LicensesPage,
    ArticlesPage,
    DocumentLibraryPage,
} from 'apps/vendor/pages/Administration'
import { DashboardPage } from 'apps/vendor/pages/Dashboard'
import { SettingsPage } from 'apps/vendor/pages/Settings'
import { UsersPage } from 'apps/vendor/pages/Users'
import { RouteType } from 'routes/utils'

import { StripeLinkPage } from '../pages/StripeLink'
import { SubscriptionsPage } from '../pages/Subscriptions'

const getPrivateRoutes = (): RouteType[] => {
    return [
        {
            path: '/',
            element: <DashboardPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/partners',
            element: <PartnersPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/invitations',
            element: <InvitationsPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/margin-structures',
            element: <MarginStructuresPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/customers',
            element: <CustomersPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/licenses',
            element: <LicensesPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/articles',
            element: <ArticlesPage />,
            permissions: ['vendor'],
        },
        {
            path: 'administration/document-library',
            element: <DocumentLibraryPage />,
            permissions: ['vendor'],
        },
        {
            path: 'users',
            element: <UsersPage />,
            permissions: ['vendor'],
        },
        {
            path: 'stripe/link',
            element: <StripeLinkPage />,
            permissions: ['vendor'],
        },
        {
            path: 'subscriptions',
            element: <SubscriptionsPage />,
            permissions: ['vendor'],
        },
        {
            path: 'settings',
            element: <SettingsPage />,
            permissions: ['vendor'],
        },
    ]
}

export default getPrivateRoutes
