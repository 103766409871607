/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import { deleteInvitation, getInvitations } from 'api/invitations'
import acceptInvitation from 'api/invitations/acceptInvitation'
import declineInvitation from 'api/invitations/declineInvitation'
import AccordionItem from 'apps/shared/components/AccordionItem'
import {
    AcceptButton,
    DeclineButton,
    KebabButton,
} from 'apps/shared/components/Buttons'
import {
    EmptyListIcon,
    StatusIcon,
    StatusType,
} from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import NewItemModalButton from 'apps/shared/components/NewItemModalButton'
import {
    DeleteInvitationModal,
    NewInvitationModal,
} from 'apps/vendor/components/Modals'
import {
    InvitationType,
    InvitationListType,
} from 'apps/vendor/interfaces/invitations'
import { MergedListType } from 'apps/vendor/interfaces/invitationsTable'
import {
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import useToast from 'hooks/useToast'

import { InvitationAccordionBody } from './Invitations.styled'

export default function InvitationsPage() {
    const [invitations, setInvitations] = useState<InvitationListType | null>()

    const [mergedInvitationsList, setMergedInvitationsList] = useState<
        MergedListType[] | null
    >(null)

    const [activeInvitation, setActiveInvitation] = useState(
        {} as InvitationType,
    )

    const enum ModalVariant {
        HIDDEN,
        NEW,
        DELETE,
        ACCEPT,
        DECLINE,
    }
    const [showModal, setShowModal] = useState(ModalVariant.HIDDEN)

    const toggleModal = (variant: ModalVariant = ModalVariant.HIDDEN) => {
        setShowModal(variant)
    }

    const { errorToast, successToast } = useToast()

    const handleInvitation = (
        invitation: InvitationType,
        modalVariant: ModalVariant,
    ) => {
        setActiveInvitation(invitation)
        toggleModal(modalVariant)
    }

    const updateInvitations = () => {
        getInvitations().then((response) => setInvitations(response.data))
        toggleModal(ModalVariant.HIDDEN)
    }

    const mergeList = (list: InvitationListType) => {
        const { incoming, outgoing } = list

        setMergedInvitationsList([
            ...incoming.map((invite) => {
                return {
                    ...invite,
                    direction: 'incoming',
                    email: invite.from_email,
                }
            }),
            ...outgoing.map((invite) => {
                return {
                    ...invite,
                    direction: 'outgoing',
                    email: invite.to_email,
                }
            }),
        ])
    }

    useEffect(() => {
        updateInvitations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!invitations) {
            return
        }
        mergeList(invitations)
    }, [invitations])

    const onDeleteInvitation = () => {
        deleteInvitation({ id: activeInvitation.id })
            .then(() => {
                getInvitations().then((response) =>
                    setInvitations(response.data),
                )
            })
            .catch(() => {
                errorToast(
                    `Could not remove invitation to ${activeInvitation.to_email}`,
                )
            })
            .finally(() => {
                toggleModal(ModalVariant.HIDDEN)
            })
    }

    const onAcceptInvitation = () => {
        acceptInvitation({ id: activeInvitation.id })
            .then(() => {
                getInvitations().then((response) =>
                    setInvitations(response.data),
                )

                successToast(
                    `Accepted invitation from ${activeInvitation.from_email}`,
                )
            })
            .catch(() => {
                errorToast(
                    `Could not accept invitation from ${activeInvitation.from_email}`,
                )
            })
            .finally(() => {
                toggleModal(ModalVariant.HIDDEN)
            })
    }

    const onDeclineInvitation = () => {
        declineInvitation({ id: activeInvitation.id })
            .then(() => {
                getInvitations().then((response) =>
                    setInvitations(response.data),
                )

                successToast(
                    `Declined the invitation from ${activeInvitation.from_email}`,
                )
            })
            .catch(() => {
                errorToast(
                    `Could not decline invitation from ${activeInvitation.from_email}`,
                )
            })
            .finally(() => {
                toggleModal(ModalVariant.HIDDEN)
            })
    }

    const renderAcceptButton = (invitation: InvitationType) => {
        return (
            <AcceptButton
                onClick={() =>
                    handleInvitation(invitation, ModalVariant.ACCEPT)
                }
            />
        )
    }

    const renderDeclineButton = (invitation: InvitationType) => {
        return (
            <DeclineButton
                onClick={() =>
                    handleInvitation(invitation, ModalVariant.DECLINE)
                }
            />
        )
    }

    const getInvitationBody = (invitation: InvitationType) => {
        if (
            invitation.direction === 'incoming' &&
            invitation.status === 'PENDING'
        ) {
            return (
                <InvitationAccordionBody>
                    <p>{`${invitation.partner_name} would like to partner with you.`}</p>
                    <div>
                        {renderAcceptButton(invitation)}
                        {renderDeclineButton(invitation)}
                    </div>
                </InvitationAccordionBody>
            )
        }
        if (invitation.partner_name && invitation.status === 'PENDING') {
            return (
                <InvitationAccordionBody>
                    <p>{`We are still waiting for a response from ${invitation.partner_name}`}</p>
                    <KebabButton
                        onDelete={() =>
                            handleInvitation(invitation, ModalVariant.DELETE)
                        }
                    />
                </InvitationAccordionBody>
            )
        }
        if (invitation.status === 'PENDING') {
            return (
                <InvitationAccordionBody>
                    <p>Awaiting response.</p>
                    <KebabButton
                        onDelete={() =>
                            handleInvitation(invitation, ModalVariant.DELETE)
                        }
                    />
                </InvitationAccordionBody>
            )
        }

        return (
            <InvitationAccordionBody>
                <KebabButton
                    onDelete={() =>
                        handleInvitation(invitation, ModalVariant.DELETE)
                    }
                />
            </InvitationAccordionBody>
        )
    }

    const renderStatus = (invitation: InvitationType) => {
        if (invitation.status === 'APPROVED') {
            return (
                <StatusIcon
                    type={StatusType.SUCCESS}
                    text={invitation.status}
                />
            )
        }
        if (invitation.status === 'DECLINED') {
            return (
                <StatusIcon type={StatusType.DANGER} text={invitation.status} />
            )
        }

        return <StatusIcon type={StatusType.WARNING} text={invitation.status} />
    }

    const renderAccordionItems = () => {
        if (!mergedInvitationsList) {
            return null
        }

        return mergedInvitationsList.map((invitation) => {
            return (
                <AccordionItem
                    key={`${invitation.direction}-invitation-${invitation.id}`}
                    eventKey={`${invitation.direction}-invitation-${invitation.id}`}
                    header={
                        <BadgeRow numberOfColumns={4}>
                            <span>
                                {invitation.direction.charAt(0).toUpperCase() +
                                    invitation.direction.slice(1)}
                            </span>
                            <span>{invitation.from_email}</span>
                            <span>{invitation.to_email}</span>
                            <span className="centered">
                                {renderStatus(invitation)}
                            </span>
                        </BadgeRow>
                    }
                    body={getInvitationBody(invitation)}
                />
            )
        })
    }

    const renderConfirmModal = (modalVariant: ModalVariant) => {
        return (
            <ConfirmModal
                body={
                    <p>
                        {modalVariant === ModalVariant.ACCEPT
                            ? `Accept the invitation from ${activeInvitation.from_email}?`
                            : `Decline the invitation from ${activeInvitation.from_email}?`}
                    </p>
                }
                onClose={() => toggleModal(ModalVariant.HIDDEN)}
                onSubmit={
                    modalVariant === ModalVariant.ACCEPT
                        ? onAcceptInvitation
                        : onDeclineInvitation
                }
                customSubmitText={
                    modalVariant === ModalVariant.ACCEPT ? 'Accept' : 'Decline'
                }
            />
        )
    }

    const renderTable = () => {
        if (!invitations && !mergedInvitationsList) {
            return null
        }

        if (mergedInvitationsList?.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={4}>
                    <span>Type</span>
                    <span>From</span>
                    <span>To</span>
                    <span className="centered">Status</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {renderAccordionItems()}
                </AccordionWrapper>
            </>
        )
    }

    return (
        <div>
            <NewItemModalButton
                header="Invitations"
                onButtonClicked={() => toggleModal(ModalVariant.NEW)}
            />
            {showModal === ModalVariant.NEW && (
                <NewInvitationModal
                    onClose={() => toggleModal(ModalVariant.HIDDEN)}
                    onSubmit={updateInvitations}
                />
            )}
            {showModal === ModalVariant.DELETE && (
                <DeleteInvitationModal
                    onClose={() => toggleModal(ModalVariant.HIDDEN)}
                    onSubmit={onDeleteInvitation}
                    invitation={activeInvitation}
                />
            )}
            {(showModal === ModalVariant.ACCEPT ||
                showModal === ModalVariant.DECLINE) && (
                <>{renderConfirmModal(showModal)}</>
            )}
            {renderTable()}
        </div>
    )
}
