import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { getLogo } from 'api/vendors/logos'
import ProfileModal from 'apps/vendor/components/Modals/ProfileModal'
import useAppDispatch from 'hooks/useAppDispatch'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'
import { logoutUser } from 'state/user/actions'

import {
    CircleIcon,
    CompanyAndUserContainer,
    PageHeadWrapper,
    SettingsDropdown,
    VendorIcon,
    BadgeContainer,
    DisplayIconContainer,
    DisplayName,
    CompanyIconAndNameContainer,
} from './PageHeader.styled'

export default function PageHeader() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { user } = useUser()
    const { vendor } = useVendor()
    const [logoUrl, setLogoUrl] = useState('')
    const [showProfileModal, setShowProfileModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [dropdownOpen, setDropdownOpen] = useState(false)

    useEffect(() => {
        getLogo()
            .then((res) => {
                setLogoUrl(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const logout = () => {
        dispatch(logoutUser()).then(() => {
            navigate(0)
        })
    }

    const renderCompanyLogo = () => {
        return logoUrl ? (
            <img src={logoUrl} alt="logo" />
        ) : (
            <CompanyIconAndNameContainer>
                <i className="fa-solid fa-building" />
                <span>{vendor?.name}</span>
            </CompanyIconAndNameContainer>
        )
    }

    const renderCompanyIcon = () => {
        if (loading || !vendor) return null

        return (
            <DisplayIconContainer>
                <VendorIcon
                    className={`display-icon ${!logoUrl ? 'no-logo' : ''}`}
                >
                    {renderCompanyLogo()}
                    <i
                        className={`fa-light fa-chevron-${
                            dropdownOpen ? 'up' : 'down'
                        }`}
                    />
                </VendorIcon>
            </DisplayIconContainer>
        )
    }

    const renderCompanyContainer = () => {
        return (
            <BadgeContainer>
                <SettingsDropdown
                    className="settings-dropdown"
                    onToggle={(
                        isOpen: boolean | ((prevState: boolean) => boolean),
                    ) => setDropdownOpen(isOpen)}
                >
                    <Dropdown.Toggle>{renderCompanyIcon()}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => navigate('/settings')}>
                            <div className="fa-solid fa-building profile" />
                            Settings
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </SettingsDropdown>
            </BadgeContainer>
        )
    }

    const renderUserIcon = () => {
        if (loading || !user) return null

        const hasUserAndName = user && user.first_name && user.last_name

        const userInitials = hasUserAndName ? (
            `${user.first_name[0]}${user.last_name[0]}`
        ) : (
            <i className="fa-solid fa-user" />
        )

        const userName = hasUserAndName
            ? `${user?.first_name} ${user?.last_name}`
            : ''

        return (
            <DisplayIconContainer>
                <>
                    <CircleIcon className="display-icon">
                        {userInitials}
                    </CircleIcon>
                    <DisplayName className="display-name">
                        {userName}
                    </DisplayName>
                </>
            </DisplayIconContainer>
        )
    }

    const renderUserContainer = () => {
        return (
            <BadgeContainer>
                <SettingsDropdown className="settings-dropdown">
                    <Dropdown.Toggle>{renderUserIcon()}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setShowProfileModal(true)}
                        >
                            <div className="fa-solid fa-user profile" />
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>
                            <div
                                className="
                            fa-solid fa-right-from-bracket sign-out"
                            />
                            Sign Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </SettingsDropdown>
            </BadgeContainer>
        )
    }

    const renderIcons = () => {
        return (
            <CompanyAndUserContainer>
                {renderCompanyContainer()}
                {renderUserContainer()}
            </CompanyAndUserContainer>
        )
    }

    return (
        <>
            {showProfileModal && (
                <ProfileModal
                    onClose={() => setShowProfileModal(!showProfileModal)}
                    onSubmit={() => setShowProfileModal(!showProfileModal)}
                />
            )}
            <PageHeadWrapper>{renderIcons()}</PageHeadWrapper>
        </>
    )
}
