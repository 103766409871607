import { API } from 'api'
import { loginUserParams } from 'api/utils/interfaces'
import { AxiosError, AxiosRequestConfig } from 'axios'

function toFormUrlEncoded(params: { [key: string]: any }) {
    return Object.keys(params)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
        )
        .join('&')
}

export default async function userLogin(params: loginUserParams): Promise<any> {
    const formData = toFormUrlEncoded({
        email: params.email,
        password: params.password,
    })

    const headers: AxiosRequestConfig['headers'] = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    try {
        const response = await API.post('auth/login/', formData, headers)

        return response.data
    } catch (error: any) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(error.response.data?.detail || 'Login failed')
        }

        throw new Error('Invalid credentials')
    }
}
