import React from 'react'
import { Nav } from 'react-bootstrap'

import NavItem, { navType } from 'apps/shared/components/NavItem'

import { Line } from './NavList.styled'

export default function NavList(props: { navItemList: navType[] }) {
    const { navItemList } = props

    return (
        <Nav>
            {navItemList.map((navItem) => (
                <Nav.Link key={navItem.text}>
                    {navItem.borderTop && <Line />}
                    <NavItem
                        text={navItem.text}
                        icon={`navItem ${navItem.icon}`}
                        path={navItem.path}
                        subItems={navItem.subItems}
                    />
                </Nav.Link>
            ))}
        </Nav>
    )
}
