import { API } from 'api'
import { requestPasswordResetParams } from 'api/utils/interfaces'
import { AxiosRequestConfig } from 'axios'

export default function requestPasswordReset(
    params: requestPasswordResetParams,
): Promise<any> {
    const headers: AxiosRequestConfig['headers'] = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    return API.post(
        `auth/accounts/password/reset/confirm/${params.uid}/${params.token}/`,
        params,
        headers,
    )
}
