import styled from 'styled-components'
import { primary } from 'utils/colors'

export const SubItemsContainer = styled.div`
    position: relative;
    margin-left: 44px;
    padding-left: 16px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: calc(100% - 1.2em);
        background-color: ${primary.lightGray};
    }

    /* Create connecting lines to each sub-item */
    & > button::before {
        content: '';
        position: absolute;
        left: -16px;
        top: 58%;
        width: 24px;
        height: 1px;
        background-color: ${primary.lightGray};
    }

    & > button.active::before {
        background-color: ${primary.blue};
        height: 2px;
    }
`

export const StyledSubItemButton = styled.button`
    position: relative;
    margin: 4px 0 !important;
    padding: 8px 16px !important;
    height: 2em !important;
    width: 100%;
    text-align: left;
    font-size: 0.875rem;
    color: ${primary.lightGray};
    transition: all 0.2s;

    &:hover {
        color: ${primary.darkGray};
    }

    &.active {
        color: ${primary.blue};
        font-weight: 500;
    }
`
