import React, { useEffect, useRef, useState } from 'react'
import { Tab } from 'react-bootstrap'

import { editLicense, addLicense } from 'api/licenses'
import getPartnersMarginStructures from 'api/partners/getPartnerMarginStructures'
import getPartnersArticles from 'api/partners/getPartnersArticles'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import {
    AlertWrapper,
    ErrorMessage,
    Switch,
    TabWrapper,
} from 'apps/shared/shared.styled'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
    SalesTierType,
    LicenseStatus,
    Term,
    TimePeriod,
    NotificationSettings,
    CustomerStatus,
} from 'apps/vendor/interfaces/subscriptions'
import { PartnerType, ArticleType } from 'apps/vendor/pages/Administration'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'

import { ModalWrapper } from '../Modals.styled'
import { ActionsTab, ArticlesTab, BillingTab, LicenseTab } from './tabs'

export type ArticleSetType = {
    quantity: number
    article: ArticleType
    description?: string
}

type LicenseModalProps = {
    onClose: () => void
    onSubmit: () => void
    articles: ArticleType[]
    marginStructures: MarginStructureType[]
    partners: PartnerType[]
    preselectedLicense?: LicenseType | null
    licenseCustomer?: string | null
    customers: CustomerType[]
}

export default function LicenseModal(defaultProps: LicenseModalProps) {
    const {
        onClose,
        onSubmit,
        articles,
        marginStructures,
        partners,
        preselectedLicense,
        licenseCustomer,
        customers,
    } = defaultProps
    const { vendor } = useVendor()
    const { user } = useUser()

    const partnerOwnedLicense = preselectedLicense
        ? preselectedLicense.vendor !== vendor?.id
        : false
    const [name, setName] = useState(preselectedLicense?.name || '')

    const [licenseKey, setLicenseKey] = useState(
        preselectedLicense?.license_key || '',
    )
    const [licenseKeyError, setLicenseKeyError] = useState('')

    const [usePartnerArticles, setUsePartnerArticles] = useState(
        (preselectedLicense &&
            preselectedLicense.articles[0].article.vendor !== vendor?.id) ||
            false,
    )

    const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
        partners[
            partners.findIndex(
                (partner) =>
                    partner.id ===
                    preselectedLicense?.articles[0].article.vendor,
            )
        ] || null,
    )

    const [selectedCustomer, setSelectedCustomer] =
        useState<CustomerType | null>(null)

    const [selectedArticles, setSelectedArticles] = useState<ArticleSetType[]>(
        preselectedLicense?.articles || [],
    )

    const [articleSetDescriptionErrors, setArticleSetDescriptionErrors] =
        useState<{
            [key: number]: string
        }>({})
    const [articleList, setArticleList] = useState<ArticleType[] | null>(null)
    const [articleError, setArticleError] = useState('')

    const [selectedMarginStructure, setSelectedMarginStructure] =
        useState<MarginStructureType | null>(
            preselectedLicense?.margin_structure || null,
        )

    const [selectedSalesTiers, setSelectedSalesTiers] = useState<
        SalesTierType[]
    >(preselectedLicense?.sales_tiers || [])

    const [marginStructureError, setMarginStructureError] = useState(false)
    const [salesTierError, setSalesTierError] = useState('')

    const [marginStructureList, setMarginStructureList] = useState<
        MarginStructureType[]
    >([])

    const [selectedStartDate, setSelectedStartDate] = useState(
        preselectedLicense?.start_date || new Date().toISOString().slice(0, 10),
    )

    const [selectedInvoiceStartDate, setSelectedInvoiceStartDate] = useState(
        preselectedLicense
            ? preselectedLicense.sharlic_invoice_period_start_date
            : selectedStartDate,
    )

    const [status, setStatus] = useState<LicenseStatus>(
        preselectedLicense ? preselectedLicense.status : LicenseStatus.ACTIVE,
    )

    const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(
        preselectedLicense?.payment_frequency || TimePeriod.MONTHLY,
    )

    const [selectedTerm, setSelectedTerm] = useState(
        preselectedLicense?.term || Term.ONE_MONTH,
    )

    const [priceAdjustmentPercentage, setPriceAdjustmentPercentage] = useState<
        number | string
    >(
        Number(preselectedLicense?.rebate) ||
            Number(preselectedLicense?.markup) ||
            '',
    )

    const [isRebate, setIsRebate] = useState(
        Number(preselectedLicense?.markup) === 0 || false,
    )
    const [priceAdjustmentError, setPriceAdjustmentError] = useState('')
    const [priceAdjustmentWarning, setPriceAdjustmentWarning] = useState('')

    const [percentagePerTier, setPercentagePerTier] = useState<string[]>(
        preselectedLicense?.margin_structure?.percentage_per_tier.split(',') ||
            [],
    )

    const [renewalNotifications, setRenewalNotifications] = useState(false)

    const [notificationSettings, setNotificationSettings] = useState<
        NotificationSettings[]
    >(preselectedLicense?.notifications || [])

    const [renewalNotificationsError, setRenewalNotificationsError] =
        useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const { errorToast, successToast } = useToast()

    const [manualInvoicing, setManualInvoicing] = useState<boolean>(
        preselectedLicense?.manual_invoicing || false,
    )
    const [showBillingInfo, setShowBillingInfo] = useState(manualInvoicing)
    const inputRef = useRef<HTMLInputElement>(null)
    const [activeTab, setActiveTab] = useState('license')

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        const initialShowArticleSetDescriptions: { [key: number]: boolean } = {}

        selectedArticles.forEach((article) => {
            initialShowArticleSetDescriptions[article.article.id] =
                (article.description?.length || 0) > 0
        })

        setRenewalNotifications(!!notificationSettings.length)

        if (preselectedLicense) {
            setSelectedCustomer(
                customers.find(
                    (customer) => customer.id === preselectedLicense.customer,
                ) || null,
            )
        } else if (customers.length === 1) {
            setSelectedCustomer(customers[0])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const parsedValue = Number(priceAdjustmentPercentage)

        if (isRebate) {
            if (parsedValue < 0 || parsedValue > 100) {
                setPriceAdjustmentError(
                    'Value needs to be a number between 0-100.',
                )
            }
        }

        setPriceAdjustmentWarning(
            parsedValue >= 30
                ? 'You are setting a unusually high value.' +
                      ' Please make sure this is correct.'
                : '',
        )
    }, [
        isRebate,
        priceAdjustmentPercentage,
        selectedInvoiceStartDate,
        selectedStartDate,
    ])

    useEffect(() => {
        if (renewalNotifications) {
            setRenewalNotificationsError(notificationSettings.length === 0)
        } else {
            setRenewalNotificationsError(false)
        }
    }, [renewalNotifications, notificationSettings])

    const areNotificationSettingsEqual = (
        settings1: NotificationSettings[],
        settings2: NotificationSettings[],
    ): boolean => {
        // First check lengths - if different, they can't be equal
        if (settings1.length !== settings2.length) {
            return false
        }

        // Sort both arrays to ensure consistent comparison
        const sortedSettings1 = [...settings1].sort(
            (a, b) => a.email.localeCompare(b.email) || a.delta - b.delta,
        )

        const sortedSettings2 = [...settings2].sort(
            (a, b) => a.email.localeCompare(b.email) || a.delta - b.delta,
        )

        return sortedSettings1.every(
            (setting, index) =>
                setting.email === sortedSettings2[index].email &&
                setting.delta === sortedSettings2[index].delta,
        )
    }

    useEffect(() => {
        let formDirty = true
        let selectedArticlesEdited = false
        let selectedSalesTiersEdited = false
        let selectedCustomerEdited = false
        let priceAdjustmentEdited = false
        let notificationSettingsEdited = false

        if (preselectedLicense) {
            selectedArticlesEdited =
                selectedArticles.length !== preselectedLicense.articles.length

            selectedArticles.forEach((article) => {
                preselectedLicense.articles.forEach(
                    (preselectedArticle: any) => {
                        if (
                            article.article.id === preselectedArticle.article.id
                        ) {
                            if (
                                article.quantity !==
                                    preselectedArticle.quantity ||
                                article.description !==
                                    preselectedArticle.description
                            ) {
                                selectedArticlesEdited = true
                            }
                        }
                    },
                )
            })

            if (
                selectedSalesTiers.length !==
                preselectedLicense.sales_tiers.length
            ) {
                selectedSalesTiersEdited = true
            } else {
                selectedSalesTiersEdited = false
                for (let i = 0; i < selectedSalesTiers.length; i += 1) {
                    if (
                        selectedSalesTiers[i]?.vendor !==
                        preselectedLicense.sales_tiers[i]?.vendor
                    ) {
                        selectedSalesTiersEdited = true
                        break
                    }
                }
            }

            selectedCustomerEdited =
                preselectedLicense.customer !== selectedCustomer?.id

            if (isRebate) {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.rebate)
            } else {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.markup)
            }

            if (
                notificationSettings &&
                preselectedLicense &&
                preselectedLicense.notifications
            ) {
                notificationSettingsEdited = !areNotificationSettingsEqual(
                    notificationSettings,
                    preselectedLicense.notifications,
                )
            }

            formDirty =
                preselectedLicense.name !== name ||
                preselectedLicense.license_key !== licenseKey ||
                selectedArticlesEdited ||
                (!manualInvoicing && selectedSalesTiersEdited) ||
                selectedCustomerEdited ||
                priceAdjustmentEdited ||
                preselectedLicense.term !== selectedTerm ||
                preselectedLicense.payment_frequency !==
                    selectedPaymentFrequency ||
                preselectedLicense.start_date !== selectedStartDate ||
                (!manualInvoicing &&
                    (preselectedLicense.sharlic_invoice_period_start_date ??
                        null) !== (selectedInvoiceStartDate ?? null)) ||
                preselectedLicense.manual_invoicing !== manualInvoicing ||
                preselectedLicense.status !== status ||
                notificationSettingsEdited
        }

        const isNameSet = name !== ''
        const isCustomerSelected = selectedCustomer !== undefined
        const isLicenseKeySet = licenseKey !== ''
        const areArticlesSelected = selectedArticles.length !== 0
        const isManualInvoicing = manualInvoicing
        const isMarginStructureSelected = selectedMarginStructure !== null

        const areSalesTiersMatching =
            selectedSalesTiers.length ===
            selectedMarginStructure?.number_of_sales_tiers

        const requiredFieldsSet =
            isNameSet &&
            isCustomerSelected &&
            isLicenseKeySet &&
            areArticlesSelected &&
            (isManualInvoicing ||
                (isMarginStructureSelected && areSalesTiersMatching))

        const hasErrors =
            salesTierError !== '' ||
            marginStructureError ||
            licenseKeyError !== '' ||
            articleError !== '' ||
            priceAdjustmentError !== '' ||
            renewalNotificationsError ||
            Object.values(articleSetDescriptionErrors).some(
                (error) => error !== '',
            )

        setCanSubmit(requiredFieldsSet && !hasErrors && formDirty)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        selectedCustomer,
        licenseKey,
        selectedArticles,
        selectedMarginStructure,
        selectedSalesTiers,
        selectedStartDate,
        selectedTerm,
        selectedPaymentFrequency,
        priceAdjustmentPercentage,
        salesTierError,
        licenseKeyError,
        articleError,
        priceAdjustmentError,
        isRebate,
        selectedInvoiceStartDate,
        status,
        articleSetDescriptionErrors,
        manualInvoicing,
        marginStructureError,
        notificationSettings,
        renewalNotificationsError,
    ])

    useEffect(() => {
        if (!usePartnerArticles && vendor) {
            const tempFilteredArticles = articles.filter(
                (article) =>
                    !selectedArticles.some(
                        (selectedArticle) =>
                            article.id === selectedArticle.article.id,
                    ),
            )

            setArticleList(tempFilteredArticles)
            setMarginStructureList(marginStructures)
            setSelectedPartner(null)
        } else if (partners.length === 1) {
            setSelectedPartner(partners[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usePartnerArticles, vendor])

    useEffect(() => {
        if (selectedPartner && selectedPartner.id) {
            getPartnersArticles(selectedPartner.id).then((res) => {
                const data = res.data.filter((article: ArticleType) => {
                    return !selectedArticles.some((selectedArticle) => {
                        return article.id === selectedArticle.article.id
                    })
                })

                setArticleList(data)
            })

            getPartnersMarginStructures(selectedPartner.id).then((res) => {
                setMarginStructureList(res.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPartner])

    useEffect(() => {
        const vendorSet = new Set(
            selectedSalesTiers.map((tier) => tier?.vendor?.id),
        )
        const hasDuplicates = vendorSet.size !== selectedSalesTiers.length

        const containsVendor = vendor
            ? selectedSalesTiers.some((tier) => tier?.vendor?.id === vendor.id)
            : false

        const isSalesTiersComplete = !!(
            selectedMarginStructure &&
            selectedSalesTiers.length !==
                selectedMarginStructure.number_of_sales_tiers
        )

        const isVendorRequired = !!(
            vendor &&
            selectedSalesTiers.length ===
                selectedMarginStructure?.number_of_sales_tiers &&
            selectedMarginStructure.number_of_sales_tiers > 1 &&
            !containsVendor
        )

        setMarginStructureError(
            !manualInvoicing &&
                ((status === LicenseStatus.ACTIVE &&
                    !selectedMarginStructure) ||
                    (status === LicenseStatus.INACTIVE &&
                        !selectedMarginStructure)),
        )

        let salesTierHasError = ''
        if (isSalesTiersComplete) {
            salesTierHasError = `Please ensure you have selected all
            ${selectedMarginStructure?.number_of_sales_tiers} sales tiers.`
        } else if (hasDuplicates) {
            salesTierHasError =
                'Make sure none of the sales tiers have the same vendor.'
        } else if (isVendorRequired) {
            salesTierHasError =
                'You need to be part of the sales tiers in any level.'
        }

        setSalesTierError(salesTierHasError)
    }, [
        manualInvoicing,
        selectedInvoiceStartDate,
        selectedMarginStructure,
        selectedSalesTiers,
        status,
        vendor,
    ])

    useEffect(() => {
        if (
            selectedCustomer &&
            selectedArticles.length > 0 &&
            selectedCustomer.currency !== selectedArticles[0].article.currency
        ) {
            setSelectedArticles([])
            setArticleList(articles)
        }
    }, [selectedCustomer, selectedArticles, articles])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const articleSets = selectedArticles.map((article) => ({
            quantity: article.quantity,
            article: article.article.id,
            description: article.description,
        }))

        const salesTiers = selectedSalesTiers.map((tier) => ({
            order: tier.order,
            vendor: tier.vendor.id,
        }))

        const licenseData = {
            name,
            customer: selectedCustomer?.id,
            rebate: isRebate ? Number(priceAdjustmentPercentage) : 0,
            markup: !isRebate ? Number(priceAdjustmentPercentage) : 0,
            license_key: licenseKey.toString(),
            articles: articleSets,
            margin_structure: manualInvoicing
                ? null
                : selectedMarginStructure?.id,
            sales_tiers: salesTiers,
            payment_frequency: selectedPaymentFrequency,
            term: selectedTerm,
            start_date: selectedStartDate,
            sharlic_invoice_period_start_date: selectedInvoiceStartDate,
            notifications: renewalNotifications ? notificationSettings : [],
            manual_invoicing: manualInvoicing,
            status,
        }

        const apiCall = preselectedLicense
            ? editLicense(licenseData, preselectedLicense.id)
            : addLicense(licenseData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedLicense
                        ? `License successfully edited.`
                        : `License successfully added.`,
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return setLicenseKeyError('License key already exists.')
                }
                if (
                    err.response.status === 400 &&
                    JSON.stringify(err.response.data.detail).includes(
                        'Invalid email',
                    )
                ) {
                    return errorToast(`Invalid email`)
                }
                if (err.response.status !== 500) {
                    const process = preselectedLicense ? 'edit' : 'add'

                    return errorToast(
                        `Failed to ${process} license, please try again.`,
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const handleSetLicenseKey = (key: string) => {
        setLicenseKeyError('')
        setLicenseKey(key)
    }

    const checkValidArticleCurrencies = (articleSetList: ArticleSetType[]) => {
        if (articleSetList.length === 0) {
            return
        }

        const { currency } = articleSetList[0].article

        const matchingCurrencies = articleSetList.every(
            (article) => article.article.currency === currency,
        )

        if (!matchingCurrencies) {
            setArticleError('All articles must have the same currency.')

            return
        }
        setArticleError('')
    }

    const handleAddArticle = (article: ArticleType) => {
        const tempSelectedArticleList = [
            ...selectedArticles,
            { quantity: 1, article, description: '' },
        ]
        setSelectedArticles(tempSelectedArticleList)

        if (!articleList) {
            return
        }
        setArticleList(articleList.filter((item) => item !== article))

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleRemoveArticle = (article: ArticleType) => {
        const tempSelectedArticleList = selectedArticles.filter(
            (item) => item.article.id !== article.id,
        )

        setSelectedArticles(tempSelectedArticleList)
        if (!articleList) {
            return
        }
        setArticleList([...articleList, article])

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleUpdateArticleQuantity = (
        quantity: number,
        article: ArticleType,
    ) => {
        if (quantity === 0) {
            setArticleError(
                'Quantity must be greater than zero. Remove article instead.',
            )
        } else {
            setArticleError('')
        }

        const updatedArticles = selectedArticles.map((item) => {
            if (item.article.id === article.id) {
                return {
                    ...item,
                    quantity,
                }
            }

            return item
        })
        setSelectedArticles(updatedArticles)
    }

    const handleUpdateArticleDescription = (
        description: string,
        article: ArticleType,
    ) => {
        const errorMessage =
            description.length > 120
                ? 'Description must be less than 120 characters.'
                : ''

        setArticleSetDescriptionErrors(
            (prevState: { [key: string]: string }) => ({
                ...prevState,
                [article.id]: errorMessage,
            }),
        )

        if (errorMessage) {
            return
        }

        const updatedSelectedArticles = selectedArticles.map((item) =>
            item.article.id === article.id ? { ...item, description } : item,
        )
        setSelectedArticles(updatedSelectedArticles)
    }

    const handleSetSalesTiers = (order: number, id: number) => {
        if (!vendor) {
            return
        }

        const tempTierList = [...selectedSalesTiers]

        const indexToReplace = tempTierList.findIndex(
            (tier) => tier.order === order,
        )

        const selectedTierPartner = partners.find(
            (partner) => partner.id === id,
        )

        if (indexToReplace !== -1) {
            tempTierList[indexToReplace] = {
                order,
                vendor: selectedTierPartner || vendor,
            }
        } else {
            tempTierList.push({
                order,
                vendor: selectedTierPartner || vendor,
            })
        }

        setSelectedSalesTiers(tempTierList)
    }

    const handleSetSelectedPartner = (index: number) => {
        const partner = partners[index]

        if (selectedPartner === partner) {
            return
        }

        setSelectedPartner(partner)
        setSelectedSalesTiers([{ order: 1, vendor: partner }])
        setSelectedArticles([])
        setSelectedMarginStructure(null)
        setSelectedSalesTiers([])
    }

    const handleSetMarginStructure = (id: string) => {
        const filteredMarginStructure = marginStructureList.find(
            (marginStructure) => marginStructure.id === parseInt(id, 10),
        )

        setSelectedSalesTiers([])
        if (!filteredMarginStructure) {
            return
        }
        setSelectedMarginStructure(filteredMarginStructure)

        setPercentagePerTier(
            filteredMarginStructure.percentage_per_tier.split(','),
        )

        const vendorToUse =
            usePartnerArticles && selectedPartner ? selectedPartner : vendor

        const salesTiers = [
            { order: 1, vendor: vendorToUse },
            ...(filteredMarginStructure.number_of_sales_tiers === 2 &&
            vendor !== vendorToUse
                ? [{ order: 2, vendor }]
                : []),
        ] as SalesTierType[]

        setSelectedSalesTiers(salesTiers)
    }

    const renderErrorMessage = (message: string) => {
        if (!message) {
            return null
        }

        return <ErrorMessage>{message}</ErrorMessage>
    }

    const handleArticleRadioChange = () => {
        setUsePartnerArticles(!usePartnerArticles)
        setSelectedArticles([])
        setSelectedPartner(null)
        setSelectedMarginStructure(null)
        setArticleList(null)
        setSelectedSalesTiers([])
    }

    const onCustomerSelected = (id: number) => {
        setSelectedCustomer(customers.find((obj) => obj.id === id) || null)
    }

    const validatePriceAdjustment = (value: string) => {
        const splitValue = value.split('.')
        if (splitValue[1]?.length > 2) {
            setPriceAdjustmentError('Max two decimals allowed.')
        } else if (value.endsWith('.')) {
            setPriceAdjustmentError('Do not end on a decimal point.')
        } else if (splitValue[0]?.length > 3) {
            setPriceAdjustmentError('Max three digits before decimal allowed.')
        } else {
            setPriceAdjustmentError('')
        }
    }

    const onPriceAdjustmentChanged = (value: string) => {
        validatePriceAdjustment(value)
        setPriceAdjustmentPercentage(value)
    }

    const handleSetState = () => {
        setStatus(
            status === LicenseStatus.ACTIVE
                ? LicenseStatus.INACTIVE
                : LicenseStatus.ACTIVE,
        )
    }

    const renderToggleActiveSwitch = () => {
        return (
            <Switch
                id="license-active-switch"
                checked={status !== LicenseStatus.INACTIVE}
                label="License active"
                onChange={() => handleSetState()}
                disabled={partnerOwnedLicense}
            />
        )
    }

    const getTitle = () => {
        let title = preselectedLicense ? 'Edit' : 'New'
        title = partnerOwnedLicense ? 'View' : title

        return (
            <>
                {title}
                {partnerOwnedLicense && (
                    <span>Created by: {preselectedLicense?.vendor}</span>
                )}
            </>
        )
    }

    const renderLicenseTab = () => {
        if (activeTab !== 'license') {
            return null
        }

        const handlers = {
            handleSetLicenseKey,
            setName,
            renderErrorMessage,
            onCustomerSelected,
        }

        const states = {
            inputRef,
            name,
            partnerOwnedLicense,
            licenseKey,
            licenseKeyError,
            customers,
            selectedCustomer,
            licenseCustomer,
        }

        return <LicenseTab handlers={handlers} states={states} />
    }

    const renderArticlesTab = () => {
        if (activeTab !== 'articles') {
            return null
        }

        const handlers = {
            handleSetSelectedPartner,
            handleArticleRadioChange,
            handleAddArticle,
            handleRemoveArticle,
            handleUpdateArticleDescription,
            handleUpdateArticleQuantity,
            renderErrorMessage,
            setIsRebate,
            onPriceAdjustmentChanged,
        }

        const states = {
            partnerOwnedLicense,
            partners,
            selectedPartner,
            usePartnerArticles,
            articleList,
            selectedArticles,
            articleSetDescriptionErrors,
            articleError,
            selectedCustomer,
            user,
            isRebate,
            priceAdjustmentPercentage,
            priceAdjustmentWarning,
            priceAdjustmentError,
        }

        return <ArticlesTab handlers={handlers} states={states} />
    }

    const handleChangeManualInvoicing = (manualInvoiceSelected: boolean) => {
        setManualInvoicing(manualInvoiceSelected)
        setShowBillingInfo(manualInvoiceSelected)
    }

    const renderBillingTab = () => {
        if (activeTab !== 'billing') {
            return null
        }

        const marginStructureInfo = {
            marginStructureList,
            selectedMarginStructure,
            handleSetMarginStructure,
            usePartnerArticles,
            partnerOwnedLicense,
            selectedPartner,
        }

        const salesTierInfo = {
            percentagePerTier,
            selectedSalesTiers,
            numberOfSalesTiers: selectedMarginStructure?.number_of_sales_tiers,
            handleSetSalesTiers,
            partners,
            usePartnerArticles,
            partnerOwnedLicense,
            salesTierError,
        }

        const handlers = {
            setSelectedTerm,
            setStatus,
            setSelectedPaymentFrequency,
            setSelectedStartDate,
            setSelectedInvoiceStartDate,
            handleChangeManualInvoicing,
        }

        const states = {
            selectedTerm,
            partnerOwnedLicense,
            status,
            selectedPaymentFrequency,
            selectedStartDate,
            selectedInvoiceStartDate,
            manualInvoicing,
            marginStructureInfo,
            salesTierInfo,
        }

        return <BillingTab handlers={handlers} states={states} />
    }

    const renderActionsTab = () => {
        if (activeTab !== 'actions') {
            return null
        }

        const handlers = {
            setRenewalNotifications,
            setNotificationSettings,
        }

        const states = {
            partnerOwnedLicense,
            renewalNotifications,
            notificationSettings,
        }

        return <ActionsTab handlers={handlers} states={states} />
    }

    const renderAlert = () => {
        if (!showBillingInfo) return null

        return (
            <AlertWrapper
                variant="danger"
                dismissible
                onClose={() => setShowBillingInfo(false)}
                show={showBillingInfo}
                className="info"
            >
                <i className="fa-regular fa-circle-info" />
                <div>
                    <h6 className="info-title">Manual billing</h6>
                    <span className="info-text">
                        Sharlic will not invoice the customer, no revenue
                        sharing or automated kickbacks will be handled.
                    </span>
                </div>
            </AlertWrapper>
        )
    }

    return (
        <Modal
            size="lg"
            onClose={onClose}
            title={getTitle()}
            body={
                <ModalWrapper>
                    {renderToggleActiveSwitch()}
                    <TabWrapper
                        className="body"
                        activeKey={activeTab}
                        onSelect={(key) => setActiveTab(key || 'license')}
                    >
                        <Tab
                            title="License"
                            eventKey="license"
                            id="0"
                            className="active"
                        />
                        <Tab title="Articles" eventKey="articles" id="1" />
                        <Tab title="Billing" eventKey="billing" id="2" />
                        <Tab title="Actions" eventKey="actions" id="3" />
                    </TabWrapper>
                    {renderLicenseTab()}
                    {renderArticlesTab()}
                    {renderBillingTab()}
                    {renderActionsTab()}
                </ModalWrapper>
            }
            footer={
                partnerOwnedLicense ? null : (
                    <CancelSubmitFooter
                        onClose={onClose}
                        onSubmit={handleSubmit}
                        canSubmit={canSubmit}
                        info={renderAlert()}
                    />
                )
            }
        />
    )
}
