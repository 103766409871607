import styled from 'styled-components'

export const OtpInputField = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    * {
        max-width: 2.5rem;
    }
    &:disabled {
        background-color: #e9ecef;
    }
`
