/* eslint-disable max-len */
import React from 'react'
import { Button } from 'react-bootstrap'

import { NewItemModalButtonWrapper } from './NewItemModalButton.styled'

export default function NewItemModalButton(defaultProps: {
    header?: string
    onButtonClicked?: () => void
}) {
    const { onButtonClicked } = defaultProps

    return (
        <NewItemModalButtonWrapper>
            {onButtonClicked && (
                <Button
                    className="btn-lg absolute-button"
                    onClick={() => onButtonClicked()}
                >
                    + New
                </Button>
            )}
        </NewItemModalButtonWrapper>
    )
}
