import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import authenticateTwoFactor from 'api/users/authenticateTwoFactor'
import { OneTimeTokenField } from 'apps/shared/components/FormInputFields'
import { SharlicLogo } from 'apps/shared/components/Logo'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

import { Banner, LoginForm } from '../Login/Login.styled'

export default function AuthenticateTwoFactorAuthPage() {
    const [totp, setTotp] = useState('')
    const { token: pathToken } = useParams()
    const { successToast, errorToast } = useToast()
    const { refetchUser } = useUser()
    const navigate = useNavigate()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        authenticateTwoFactor({
            totp_code: totp,
            token: pathToken || '',
        })
            .then((response) => {
                if (response.status === 200) {
                    successToast('Logged in successfully')
                    refetchUser()
                    navigate('/')
                }
            })
            .catch(() => {
                errorToast('Invalid code')
                setTotp('')
            })
    }

    const handleTokenChange = (e: string) => {
        setTotp(e)
    }

    const renderBanner = () => (
        <Banner>
            <SharlicLogo />
            <Container>
                <h2>Enter code from Authenticator app</h2>
            </Container>
        </Banner>
    )

    const renderFormGroup = () => (
        <Form.Group id="submit-and-recover" style={{ marginTop: '2rem' }}>
            <Button type="submit" variant="primary" disabled={totp.length < 6}>
                Submit
            </Button>
        </Form.Group>
    )

    return (
        <LoginForm>
            <Form
                onSubmit={handleSubmit}
                onKeyDown={(e) => e.key === 'Enter' && handleSubmit}
            >
                <div className="login-component">
                    {renderBanner()}
                    <OneTimeTokenField onChange={handleTokenChange} />
                    {renderFormGroup()}
                </div>
            </Form>
        </LoginForm>
    )
}
