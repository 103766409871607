import React from 'react'
import { Form, FormLabel } from 'react-bootstrap'

import { ErrorMessage } from 'apps/shared/shared.styled'
import {
    MissingAssetText,
    MultipleSection,
} from 'apps/vendor/components/Modals/Modals.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { SalesTierType } from 'apps/vendor/interfaces/subscriptions'
import { PartnerType } from 'apps/vendor/pages/Administration'
import useVendor from 'hooks/useVendor'

interface SalesTierContainerProps {
    percentagePerTier: string[]
    selectedSalesTiers: SalesTierType[]
    handleSetSalesTiers: (order: number, vendorId: number) => void
    partners: PartnerType[]
    usePartnerArticles: boolean
    partnerOwnedLicense: boolean
    salesTierError: string
    numberOfSalesTiers?: number
}

export default function SalesTierContainer(
    defaultProps: SalesTierContainerProps,
) {
    const {
        percentagePerTier,
        selectedSalesTiers,
        numberOfSalesTiers,
        handleSetSalesTiers,
        partners,
        usePartnerArticles,
        partnerOwnedLicense,
        salesTierError,
    } = defaultProps
    const { vendor } = useVendor()

    if (!numberOfSalesTiers) {
        return null
    }

    const getSalesTierText = (order: number) => {
        const tierVendor = selectedSalesTiers.find(
            (tier) => tier.order === order,
        )?.vendor

        if (!tierVendor) {
            if (numberOfSalesTiers === 3 && order === 2) {
                return 'Choose distributor'
            }

            return 'Choose reseller'
        }

        if ('partner_name' in tierVendor) {
            return tierVendor.partner_name
        }

        return tierVendor.name
    }

    const renderTierOrderInfo = (order: number) => {
        return (
            <h6 key={`tier-${order}`}>
                Tier {order} ({percentagePerTier[order - 1]}%)
            </h6>
        )
    }

    const renderPredeterminedSalesTier = (order: number) => {
        const tierVendor = selectedSalesTiers.find(
            (tier) => tier.order === order,
        )?.vendor

        if (!tierVendor) {
            return null
        }

        const tierName =
            'name' in tierVendor ? tierVendor.name : tierVendor.partner_name

        return (
            <div key={`tier-${order}`}>
                {renderTierOrderInfo(order)}
                <Form.Control
                    placeholder={tierName}
                    type="text"
                    key={`control-${order}`}
                    disabled
                />
            </div>
        )
    }

    const renderSalesTierDropdown = (order: number) => {
        const vendors =
            usePartnerArticles && vendor ? [...partners, vendor] : partners

        const options = vendors.map((tierVendor) => ({
            value: tierVendor.id,
            label:
                'name' in tierVendor
                    ? tierVendor.name
                    : tierVendor.partner_name,
        }))

        const value = {
            value: partners.findIndex(
                (partner) =>
                    partner.id ===
                    selectedSalesTiers.find((tier) => tier.order === order)
                        ?.vendor.id,
            ),
            label: getSalesTierText(order),
        }

        return (
            <div key={`tier-${order}`}>
                {renderTierOrderInfo(order)}
                <SelectDropdown
                    options={options}
                    value={value}
                    key={`dropdown-${order}`}
                    onChange={(e: any) => handleSetSalesTiers(order, e)}
                    isDisabled={partnerOwnedLicense}
                    isSearchable
                />
            </div>
        )
    }

    const renderSalesTiers = () => {
        if (!numberOfSalesTiers) {
            return null
        }

        if (numberOfSalesTiers > partners.length + 1) {
            return (
                <MissingAssetText>
                    Not enough partners to assign all sales tiers.
                </MissingAssetText>
            )
        }

        const salesTiers = []

        for (let order = 1; order <= numberOfSalesTiers; order += 1) {
            const isFirstTier = order === 1

            const isTwoTierWithPartnerArticles =
                order === 2 && numberOfSalesTiers === 2 && usePartnerArticles

            if (isFirstTier || isTwoTierWithPartnerArticles) {
                salesTiers.push(renderPredeterminedSalesTier(order))
            } else {
                salesTiers.push(renderSalesTierDropdown(order))
            }
        }

        return salesTiers
    }

    const fractions = Array.from({ length: numberOfSalesTiers }, () => '1fr')

    return (
        <>
            <FormLabel>Sales Tiers</FormLabel>
            <MultipleSection fractions={fractions}>
                {renderSalesTiers()}
            </MultipleSection>
            {salesTierError && <ErrorMessage>{salesTierError}</ErrorMessage>}
        </>
    )
}
