import { API } from 'api'
import { loginUserParams } from 'api/utils/interfaces'
import { AxiosRequestConfig } from 'axios'

function toFormUrlEncoded(params: { [key: string]: any }) {
    return Object.keys(params)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
        )
        .join('&')
}

export default function initiateTwoFactor(
    params: loginUserParams,
): Promise<any> {
    const formData = toFormUrlEncoded({
        email: params.email,
        password: params.password,
    })

    const headers: AxiosRequestConfig['headers'] = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }

    return API.post('auth/2fa/initiate/', formData, headers)
}
