/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import LoadingStatus from 'state/enums/LoadingStatus'

import { loadCountryData } from './actions'
import CountryDataState from './CountryDataState'

const initialState = {
    data: undefined,
    status: LoadingStatus.IDLE,
    error: undefined,
}

const countryDataSlice = createSlice({
    name: 'countryData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCountryData.pending, (state: CountryDataState) => {
                state.status = LoadingStatus.LOADING
            })
            .addCase(
                loadCountryData.fulfilled,
                (state: CountryDataState, action) => {
                    state.status = LoadingStatus.LOADED

                    state.data = {
                        country_data: action.payload.data.country_data,
                    }
                },
            )
            .addCase(
                loadCountryData.rejected,
                (state: CountryDataState, action) => {
                    state.status = LoadingStatus.FAILED
                    state.error = action.error.message
                },
            )
    },
})

export default countryDataSlice.reducer
