import React, { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'

import { requestPasswordRecover } from 'api/users'
import { SharlicLogo } from 'apps/shared/components/Logo'
import isEmail from 'validator/lib/isEmail'

import { Banner } from '../Login/Login.styled'
import {
    HeaderAndDescriptionWrapper,
    PasswordInputWrapper,
    ResetPasswordForm,
} from './Recover.styled'

export enum UpdatePasswordType {
    RESET = 'reset_password',
    SET = 'set_password',
}

export default function RecoverPage() {
    const [email, setEmail] = useState('')
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        setInvalidEmail(!isEmail(email))
    }, [email, submitted])

    const handleSubmit = (e: any) => {
        e.preventDefault()

        requestPasswordRecover({
            email,
            type: UpdatePasswordType.RESET,
        })
        setSubmitted(true)
    }

    const handleSetEmail = (value: string) => {
        setEmail(value)
        setSubmitAttempted(false)
    }

    const renderLogoSection = () => {
        return (
            <Banner>
                <SharlicLogo />
            </Banner>
        )
    }

    const renderHeaderAndDescription = () => {
        const header = !submitted ? 'Recover Password' : 'Verify your identity'

        const description = !submitted
            ? `Please enter the email address associated with your account.`
            : `We have sent an email to ${email}. Please check your inbox and
        follow the instructions to reset your password.`

        return (
            <HeaderAndDescriptionWrapper>
                <h2>{header}</h2>
                <p className="description">{description}</p>
            </HeaderAndDescriptionWrapper>
        )
    }

    const renderEmailInput = () => {
        if (submitted) {
            return null
        }

        return (
            <Form onSubmit={handleSubmit}>
                <PasswordInputWrapper>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => handleSetEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        disabled={invalidEmail || submitAttempted}
                    >
                        Submit
                    </Button>
                </PasswordInputWrapper>
            </Form>
        )
    }

    const renderLinkToLogin = () => {
        return (
            <a href="/" id="footer">
                Return to Log in Page
            </a>
        )
    }

    return (
        <ResetPasswordForm>
            <Container>
                <div className="modify-password-component">
                    {renderLogoSection()}
                    {renderHeaderAndDescription()}
                    {renderEmailInput()}
                    {renderLinkToLogin()}
                </div>
            </Container>
        </ResetPasswordForm>
    )
}
