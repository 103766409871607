import { useCallback, useEffect } from 'react'

import LoadingStatus from 'state/enums/LoadingStatus'
import { loadUser } from 'state/user/actions'

import { UseUserInterface } from './types/useUser.types'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

const useUser = () => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)
    const status = useAppSelector((state) => state.user.status)
    const user = useAppSelector((state) => state.user.data)

    const refetchUser = useCallback(() => {
        dispatch(loadUser())
    }, [dispatch])

    useEffect(() => {
        if (status === LoadingStatus.IDLE) {
            refetchUser()
        }
    }, [status, refetchUser])

    return { isLoggedIn, user, status, refetchUser } as UseUserInterface
}

export default useUser
