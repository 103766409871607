import { Dropdown } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

const SIZES = {
    USER_ICON_SIZE: '55px',
} as const

export const PageHeadWrapper = styled.header`
    text-align: center;
    width: 100%;
    height: 140px !important;
    min-height: 140px !important;
    border-bottom: 2px solid ${secondary.whisperWhite};
    align-items: center;

    @media screen and (max-width: 575px) {
        padding-top: 2px;
        height: 74px !important;
        min-height: 74px !important;
    }
`

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    /* Only disable pointer events for direct children except the dropdown */
    > *:not(.settings-dropdown) {
        pointer-events: none;
    }
`

export const SettingsDropdown = styled(Dropdown)`
    display: inline-block;
    pointer-events: auto;

    .dropdown-toggle {
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 1em;

        &::after {
            display: none;
        }

        &:focus,
        &:active,
        &:hover {
            background: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .dropdown-menu {
        background-color: ${secondary.almostWhiteGray};
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        margin-top: 0.5rem;
        pointer-events: auto;

        &.show {
            transform: translate(0px, 57px) !important;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            color: ${primary.darkGray};
            pointer-events: auto;
            cursor: pointer;

            > div {
                width: 25px;
            }

            &:active,
            &:hover {
                background-color: ${secondary.lightGray};
            }

            .profile {
                color: ${primary.blue};
            }

            .sign-out {
                color: ${primary.red};
            }
        }
    }
`

export const DisplayIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    pointer-events: auto;

    &:hover {
        .display-icon {
            background-color: ${primary.lightGray};
        }

        .display-name {
            color: ${primary.darkGray};
            text-decoration: underline;
        }
    }
`

export const CircleIcon = styled.div`
    height: ${SIZES.USER_ICON_SIZE};
    width: ${SIZES.USER_ICON_SIZE};
    min-width: ${SIZES.USER_ICON_SIZE};
    min-height: ${SIZES.USER_ICON_SIZE};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${primary.darkBlue};
    color: white;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: 300;
    transition: background-color 0.3s linear;

    @media screen and (max-width: 575px) {
        font-size: 1em;
        font-weight: 200;
    }
`

export const VendorIcon = styled.div`
    max-width: 350px;
    height: ${SIZES.USER_ICON_SIZE};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${secondary.lightGray};
    color: white;
    border-radius: 8px;
    font-size: 1.2em;
    font-weight: 300;
    transition: background-color 0.3s linear;

    > i {
        font-size: 1.2em;
        margin-right: 4px;
        color: black;
    }

    &.no-logo {
        display: grid;
        grid-template-columns: 1fr 40px;
        min-width: 200px;
        padding: 0 0 0 1em;
        background-color: ${primary.darkBlue};

        align-items: space-between;

        > i {
            color: white;
            justify-self: end;
            padding-right: 4px;
        }
    }

    & > img {
        width: 160px;
        object-fit: contain;
        max-height: 40px;
        margin: 0 8px;
    }
`

export const DisplayName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em;
    color: ${primary.darkGray};
    font-weight: 200;
    max-width: 250px;
    min-width: 100px;

    @media screen and (max-width: 575px) {
        display: none;
    }
`

export const CompanyAndUserContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 3rem;
    width: 100%;
    height: 100%;
    padding-right: 40px;

    @media screen and (max-width: 575px) {
        margin-left: 2rem;
        margin-right: 1rem;
        gap: 0.8rem;
    }
`

export const CompanyIconAndNameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    font-size: 1em;
    font-weight: 300;

    > span {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > i {
        font-size: 1em;
    }

    @media screen and (max-width: 575px) {
        font-size: 0.8em;
        font-weight: 200;

        > span {
            max-width: 165px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`
