import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'

import { FormLabel } from 'apps/shared/shared.styled'
import { useCountryData } from 'hooks'

import { Checkbox, MultipleSection } from '../Modals/Modals.styled'
import { SelectDropdown } from '../Selects'
import { formatDisplayVat, useParseTaxDetails } from './TaxParsers'

type SelectedTaxId = {
    taxType?: string
    example?: string
    regex?: RegExp
}

interface TaxInformationContainerProps {
    selectedCountry: string
    preselectedTaxId?: string
    preselectedTaxType?: string
    preselectedTaxable?: boolean
    handleUpdateTaxId: (taxId: string) => void
    handleUpdateTaxType: (taxable: string) => void
    handleUpdateTaxable: (taxable: boolean) => void
    handleUpdateTaxableValid: (taxable: boolean) => void
}

export default function TaxInformationContainer(
    defaultProps: TaxInformationContainerProps,
) {
    const {
        selectedCountry,
        preselectedTaxId,
        preselectedTaxType,
        preselectedTaxable,
        handleUpdateTaxId,
        handleUpdateTaxType,
        handleUpdateTaxable,
        handleUpdateTaxableValid,
    } = defaultProps
    const { countryData } = useCountryData()
    const [taxId, setTaxId] = useState(preselectedTaxId || '')
    const [taxable, setTaxable] = useState(preselectedTaxable || false)
    const [isValidTaxId, setIsValidTaxId] = useState(true)
    const parsedTaxDetails = useParseTaxDetails()

    const parsedPreselectedTaxType = formatDisplayVat(
        preselectedTaxType || '',
        preselectedTaxId || '',
    )

    const [selectedTaxDetail, setSelectedTaxDetail] =
        useState<SelectedTaxId | null>()

    useEffect(() => {
        if (preselectedTaxType && preselectedTaxId) {
            const matchingTaxDetail = parsedTaxDetails.find(
                (detail) => detail.parsedTaxType === parsedPreselectedTaxType,
            )

            setSelectedTaxDetail({
                taxType: parsedPreselectedTaxType,
                example: matchingTaxDetail?.example || '',
                regex: matchingTaxDetail?.regex,
            })
        }
    }, [
        parsedTaxDetails,
        parsedPreselectedTaxType,
        preselectedTaxType,
        preselectedTaxId,
        selectedCountry,
        countryData,
        preselectedTaxable,
    ])

    // Validation function to check tax ID against regex
    const validateTaxId = (id: string, taxRegex?: RegExp): boolean => {
        if (!id) return true

        const regex = new RegExp(taxRegex || '')
        if (!regex) return false

        const isValid = regex.test(id)

        return isValid
    }

    useEffect(() => {
        if (selectedTaxDetail?.regex) {
            const isValid = validateTaxId(taxId, selectedTaxDetail.regex)
            setIsValidTaxId(isValid)
            handleUpdateTaxableValid(isValid)
        }
    }, [taxId, selectedTaxDetail, handleUpdateTaxableValid])

    const handleSetTaxType = (taxType: string) => {
        const taxDetail = parsedTaxDetails.find(
            (taxIdType) => taxIdType.parsedTaxType === taxType,
        )

        handleUpdateTaxType(taxDetail?.taxType || '')

        const newTaxDetail = {
            taxType: taxDetail?.parsedTaxType,
            example: taxDetail?.example || '',
            regex: taxDetail?.regex,
        }

        setSelectedTaxDetail(newTaxDetail)

        if (taxId) {
            const isValid = validateTaxId(taxId, newTaxDetail.regex)
            setIsValidTaxId(isValid)
        }
    }

    const renderTaxTypeDropdown = () => {
        const options = parsedTaxDetails.map((taxInfo) => ({
            value: taxInfo.parsedTaxType,
            label: taxInfo.countryFlag,
            additionalInfo: taxInfo.example,
        }))

        const selectedOption = options.find(
            (option) =>
                option.value === selectedTaxDetail?.taxType?.toLowerCase(),
        )

        const value = selectedOption || null

        return (
            <>
                <FormLabel>Tax Type</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={(option) => handleSetTaxType(option)}
                    isSearchable
                    isDisabled={!taxable}
                />
            </>
        )
    }

    const handleSetTaxId = (newTaxId: string) => {
        setTaxId(newTaxId)

        if (selectedTaxDetail?.regex) {
            const isValid = validateTaxId(newTaxId, selectedTaxDetail.regex)
            setIsValidTaxId(isValid)

            if (isValid) {
                handleUpdateTaxId(newTaxId)
            }
        }
    }

    const generateTextInputField = () => {
        const placeholder = selectedTaxDetail?.example
            ? `Example: ${selectedTaxDetail?.example}`
            : ''

        return (
            <>
                <FormLabel>Tax ID</FormLabel>
                <Form.Control
                    value={taxId}
                    type="text"
                    placeholder={placeholder}
                    onChange={(e: any) =>
                        handleSetTaxId(e.target.value.toUpperCase())
                    }
                    onBlur={(e: any) => handleSetTaxId(e.target.value.trim())}
                    disabled={!taxable}
                    className={!isValidTaxId && taxable ? 'is-invalid' : ''}
                />
                {!isValidTaxId && taxable && (
                    <Form.Control.Feedback type="invalid">
                        Invalid tax ID format
                    </Form.Control.Feedback>
                )}
            </>
        )
    }

    const handleUpdateTaxableStatus = () => {
        setTaxable(!taxable)
        handleUpdateTaxable(!taxable)
    }

    return (
        <>
            <Checkbox
                {...{
                    id: 'taxable',
                    name: 'taxable',
                    label: 'Taxable',
                    defaultChecked: taxable,
                    onChange: () => handleUpdateTaxableStatus(),
                }}
            />
            <MultipleSection fractions={['1fr', '4fr']}>
                <div>{renderTaxTypeDropdown()}</div>
                <div>{generateTextInputField()}</div>
            </MultipleSection>
        </>
    )
}
