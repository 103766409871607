import React, { useEffect, useState } from 'react'

import { getCustomers } from 'api/customers'
import { getPartners } from 'api/partners'
import { deleteDocument, getDocuments } from 'api/vendors'
import {
    DeleteButton,
    KebabButton,
    TextButton,
} from 'apps/shared/components/Buttons'
import PdfButton from 'apps/shared/components/Buttons/PdfButton'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import NewItemModalButton from 'apps/shared/components/NewItemModalButton'
import { FileUploadModal } from 'apps/vendor/components/Modals'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { VendorDocumentType } from 'apps/vendor/interfaces/documents'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'
import useVendor from 'hooks/useVendor'

import { PartnerType } from '../Partners'
import {
    ActionButtons,
    FilterAndSearchWrapper,
    LibraryRow,
    SearchBarWrapper,
    TopRowContainer,
    LibraryBadgeRow,
    LibraryHeaderRow,
} from './DocumentLibrary.styled'

export default function DocumentLibraryPage() {
    const [documents, setDocuments] = useState<VendorDocumentType[]>([])
    const [partners, setPartners] = useState<PartnerType[]>([])
    const [customers, setCustomers] = useState<CustomerType[]>([])

    const [filteredDocuments, setFilteredDocuments] = useState<
        VendorDocumentType[]
    >([])
    const [loading, setLoading] = useState(true)

    const [selectedTypeFilter, setSelectedTypeFilter] = useState<string | null>(
        null,
    )

    const [selectedRelatedToFilter, setSelectedRelatedToFilter] = useState<
        string | null
    >(null)
    const [showUploadFileModal, setShowUploadFileModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [filterActive, setFilterActive] = useState(false)

    const [selectedDocument, setSelectedDocument] =
        useState<VendorDocumentType | null>(null)
    const { vendor } = useVendor()
    const { successToast, errorToast } = useToast()
    const [numberOfColumns, setNumberOfColumns] = useState(5)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1680) {
                setNumberOfColumns(4)
            } else {
                setNumberOfColumns(5)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        setLoading(true)

        Promise.all([getPartners(), getCustomers(), getDocuments()])
            .then(([partnersRes, customersRes, documentRes]) => {
                setPartners(partnersRes.data)
                setCustomers(customersRes.data)
                setDocuments(documentRes.data)
                setLoading(false)
            })
            .catch(() => {
                errorToast('Error fetching data')
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let filtered = documents

        if (selectedTypeFilter) {
            filtered = filtered.filter(
                (document) => document.type === selectedTypeFilter,
            )
        }

        if (selectedRelatedToFilter) {
            filtered = filtered.filter((document) => {
                const relatedTo = document.related_to || ''

                return relatedTo === selectedRelatedToFilter
            })
        }

        if (searchValue) {
            const lowercasedSearchValue = searchValue.toLowerCase()

            filtered = filtered.filter((document) => {
                return (
                    (document.name &&
                        document.name
                            .toLowerCase()
                            .includes(lowercasedSearchValue)) ||
                    (document.type &&
                        document.type
                            .toLowerCase()
                            .includes(lowercasedSearchValue)) ||
                    (document.uploaded_at &&
                        document.uploaded_at
                            .toLowerCase()
                            .includes(lowercasedSearchValue)) ||
                    (document.related_to &&
                        document.related_to
                            .toLowerCase()
                            .includes(lowercasedSearchValue)) ||
                    (document.document_url &&
                        document.document_url
                            .toLowerCase()
                            .includes(lowercasedSearchValue))
                )
            })
        }

        setFilteredDocuments(filtered)

        setFilterActive(
            !!selectedTypeFilter || !!selectedRelatedToFilter || !!searchValue,
        )
    }, [selectedTypeFilter, selectedRelatedToFilter, documents, searchValue])

    const handleSetSelectedRelatedToFilter = (selectedOption: string) => {
        if (!selectedOption) {
            setSelectedRelatedToFilter(null)
        } else {
            setSelectedRelatedToFilter(selectedOption)
        }
    }

    const handleSetSelectedTypeFilter = (selectedOption: string) => {
        if (!selectedOption) {
            setSelectedTypeFilter(null)
        } else {
            setSelectedTypeFilter(selectedOption)
        }
    }

    const formatString = (str: string): string => {
        if (!str) return str

        // Replace underscores with spaces
        const formattedStr = str.replace(/_/g, ' ')

        // Capitalize only the first character
        return (
            formattedStr.charAt(0).toUpperCase() +
            formattedStr.slice(1).toLowerCase()
        )
    }

    const renderFilterTypeDropdown = () => {
        if (!documents) return null

        const uniqueTypes = Array.from(
            new Set(documents.map((document) => document.type)),
        ).sort((a, b) => a.localeCompare(b))

        const options = [
            { value: '', label: '--- Clear filter ---' },
            ...uniqueTypes.map((type, index) => ({
                value: type,
                label: formatString(type),
            })),
        ]

        const selectedOption = options.find(
            (option) => option.value === selectedTypeFilter,
        )

        const value = selectedOption || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetSelectedTypeFilter}
                placeholder="Filter by type"
                isSearchable
            />
        )
    }

    const renderFilterRelatedToDropdown = () => {
        if (!documents) return null

        const uniqueRelatedTo = Array.from(
            new Set(
                documents
                    .map((document) => document.related_to || '')
                    .filter((relatedTo) => relatedTo !== ''),
            ),
        ).sort((a, b) => a.localeCompare(b))

        const options = [
            { value: '', label: '--- Clear filter ---' },
            ...uniqueRelatedTo.map((relatedTo, index) => ({
                value: relatedTo,
                label: formatString(relatedTo),
            })),
        ]

        const selectedOption = options.find(
            (option) => option.value === selectedRelatedToFilter,
        )

        const value = selectedOption || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetSelectedRelatedToFilter}
                placeholder="Filter by related to"
                isSearchable
            />
        )
    }

    const renderSearchbar = () => {
        return (
            <SearchBarWrapper>
                <i className="fa-regular fa-magnifying-glass icon" />
                <input
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </SearchBarWrapper>
        )
    }

    const clearFilters = () => {
        setSelectedTypeFilter(null)
        setSelectedRelatedToFilter(null)
        setSearchValue('')
    }

    const renderFilterAndSearchSection = () => {
        return (
            <TopRowContainer>
                <FilterAndSearchWrapper>
                    {renderFilterTypeDropdown()}
                    {renderFilterRelatedToDropdown()}
                    {renderSearchbar()}
                </FilterAndSearchWrapper>
                {filterActive && (
                    <TextButton
                        onClick={() => clearFilters()}
                        text="Clear all filters"
                    />
                )}
            </TopRowContainer>
        )
    }

    const handleOpenPdf = (document: VendorDocumentType) => {
        window.location.href = document.document_url
    }

    const handleUpdateDocuments = () => {
        setShowUploadFileModal(false)

        getDocuments()
            .then((res: any) => {
                setDocuments(res.data.reverse())
            })
            .catch(() => {
                errorToast('Failed to fetch documents')
            })
    }

    const handleDeleteModal = (document: VendorDocumentType) => {
        setShowDeleteModal(true)
        setSelectedDocument(document)
    }

    const handleDeleteDocument = () => {
        if (!selectedDocument) return

        deleteDocument(selectedDocument.id)
            .then(() => {
                successToast('Document deleted successfully')
                setSelectedDocument(null)
                setShowDeleteModal(false)

                setDocuments(
                    documents.filter((doc) => doc.id !== selectedDocument.id),
                )
            })
            .catch(() => {
                errorToast('Failed to delete document')
            })
    }

    const renderConfirmDeleteSection = () => {
        const relatedTo = selectedDocument ? selectedDocument.related_to : ''

        return (
            <>
                <section>
                    You are about to to delete the following article:
                    <p>{selectedDocument?.name}</p>
                    <p>
                        Related to: <b>{relatedTo}</b>
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const renderDocumentTable = () => {
        if (!filteredDocuments || filteredDocuments.length === 0)
            return <EmptyListIcon />

        return (
            <>
                <LibraryHeaderRow>
                    <span>Name:</span>
                    <span>Related to:</span>
                    <span>Type:</span>
                    <span>Uploaded at:</span>
                    <span> </span>
                </LibraryHeaderRow>
                {filteredDocuments.map((document) => (
                    <LibraryRow key={document.id}>
                        <LibraryBadgeRow key={document.id} className="library">
                            <span>{document.name}</span>
                            <span>{document.related_to}</span>
                            <span>{document.type}</span>
                            <span>{document.uploaded_at}</span>
                            <ActionButtons className="flex-column">
                                {numberOfColumns === 5 ? (
                                    <>
                                        <PdfButton
                                            onClick={() =>
                                                handleOpenPdf(document)
                                            }
                                            text="Download"
                                        />
                                        <DeleteButton
                                            onClick={() =>
                                                handleDeleteModal(document)
                                            }
                                        />
                                    </>
                                ) : (
                                    <KebabButton
                                        onDownload={() =>
                                            handleOpenPdf(document)
                                        }
                                        onDelete={() =>
                                            handleDeleteModal(document)
                                        }
                                    />
                                )}
                            </ActionButtons>
                        </LibraryBadgeRow>
                    </LibraryRow>
                ))}
            </>
        )
    }

    const renderDocumentLibraryContent = () => {
        return (
            <>
                {renderFilterAndSearchSection()}
                {loading ? <LoadingSpinner /> : renderDocumentTable()}
            </>
        )
    }

    if (!vendor) return null

    return (
        <div>
            <NewItemModalButton
                onButtonClicked={() =>
                    setShowUploadFileModal(!showUploadFileModal)
                }
            />
            {renderDocumentLibraryContent()}
            {showUploadFileModal && (
                <FileUploadModal
                    onClose={() => setShowUploadFileModal(!showUploadFileModal)}
                    onUpdate={() => handleUpdateDocuments()}
                    partners={partners}
                    customers={customers}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedDocument(null)
                    }}
                    onSubmit={() => handleDeleteDocument()}
                    customSubmitText="Delete"
                    body={renderConfirmDeleteSection()}
                    deleteButton
                />
            )}
        </div>
    )
}
