import React, { useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ContentWrapper, Grid, MainContent } from 'App.styled'
import Navbar from 'apps/shared/components/Navbar'
import PageHeader from 'apps/shared/components/PageHeader'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'
import 'bootstrap/dist/css/bootstrap.min.css'
import AppRoutes from 'routes'

import './App.css'

function App() {
    const { user, refetchUser } = useUser()
    const { vendor } = useVendor()

    useEffect(() => {
        refetchUser()
    }, [refetchUser])

    return (
        <main>
            <Grid>
                {user && <Navbar />}
                <ContentWrapper>
                    {vendor && !vendor.stripeAccountLinked && (
                        <Alert className="not-scrollable" variant="warning">
                            Stripe account not linked! Click{' '}
                            <a href="/#/stripe/link">here</a> to complete the
                            setup.
                        </Alert>
                    )}
                    {vendor && user && <PageHeader />}
                    <MainContent>
                        <AppRoutes />
                    </MainContent>
                </ContentWrapper>
            </Grid>
            <ToastContainer
                position="top-right"
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </main>
    )
}

export default App
