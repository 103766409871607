import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useEnforceAriaHiddenFalse from 'hooks/useEnforceAriaHiddenFalse'

import { ButtonText } from '../Navbar/Navbar.styled'
import { NavSubItems } from '../NavSubItems'

export type navType = {
    text: string
    icon: string
    path: string
    borderTop?: boolean
    permissions?: string[]
    subItems?: navType[]
}

export default function NavItem(navObject: navType) {
    const { text, icon, path, subItems } = navObject
    const [expanded, setExpanded] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const buttonRef = useEnforceAriaHiddenFalse<HTMLButtonElement>()
    const hasSubItems = subItems && subItems.length > 0
    const isAdministration = path === '/administration'

    const hasActiveChild = subItems?.some(
        (item) => location.pathname === item.path,
    )

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (hasSubItems) {
            e.preventDefault()
            setExpanded(!expanded)
        } else {
            navigate(path)
        }
    }

    useEffect(() => {
        if (hasSubItems && hasActiveChild) {
            setExpanded(true)
        }
    }, [hasSubItems, hasActiveChild])

    const renderButtonText = () => {
        return (
            <ButtonText>
                <span>{text}</span>
                {hasSubItems && (
                    <i
                        className={`fa-regular fa-chevron-${
                            expanded ? 'down' : 'right'
                        }`}
                        style={{ marginLeft: '8px' }}
                    />
                )}
            </ButtonText>
        )
    }

    return (
        <>
            <button
                ref={buttonRef}
                type="button"
                onClick={handleClick}
                key={path}
                className={`${icon} navButton navItem ${
                    isAdministration ? 'no-active-bg' : ''
                }`}
                data-path={path}
            >
                {renderButtonText()}
            </button>
            {hasSubItems && expanded && (
                <NavSubItems
                    subItems={subItems}
                    onNavigate={navigate}
                    activePath={location.pathname}
                />
            )}
        </>
    )
}
