import React, { useEffect, useState } from 'react'
import { CountryData as CountryPhoneData } from 'react-phone-input-2'

import { useCountryData } from 'hooks'
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'

import { PhoneInputWrapper } from './PhoneInputField.styled'

interface PhoneInputData {
    phoneNumber: string
    onChange: (number: string, errorMessage: string) => void
    selectedCountry?: CountryCode
    disabled?: boolean
}

export default function PhoneInputField(defaultProps: PhoneInputData) {
    const { phoneNumber, onChange, selectedCountry, disabled } = defaultProps
    const { countryData } = useCountryData()

    const [phoneCountryCode, setPhoneCountryCode] = useState(
        selectedCountry || 'se',
    )
    const [dialCode, setDialCode] = useState('')
    const [phoneIsValid, setPhoneIsValid] = useState(true)
    const [acceptedCountries, setAcceptedCountries] = useState<string[]>([])

    useEffect(() => {
        setPhoneCountryCode(selectedCountry || 'se')
    }, [selectedCountry])

    useEffect(() => {
        if (countryData) {
            const countries = Object.keys(countryData.country_data).map(
                (country) => country.toLowerCase(),
            )
            setAcceptedCountries(countries)
        }
    }, [countryData])

    if (!acceptedCountries.length) {
        return null
    }

    const handleOnChange = (
        value: string,
        countryPhoneData: CountryPhoneData,
    ) => {
        const { countryCode } = countryPhoneData
        const formatedPhoneNumber = `+${value}`

        const phoneNumberObject =
            parsePhoneNumberFromString(formatedPhoneNumber)

        const phoneIsOnlyCountryCode = value === countryPhoneData.dialCode
        let phoneError = ''

        if (phoneNumberObject) {
            phoneError = phoneNumberObject.isValid()
                ? ''
                : 'Invalid phone number.'
        } else {
            phoneError =
                phoneIsOnlyCountryCode || !value ? '' : 'Invalid phone number.'
        }

        setPhoneIsValid(!phoneError)

        if (acceptedCountries.includes(countryCode.toUpperCase())) {
            if (countryCode !== phoneCountryCode) {
                setPhoneCountryCode(countryCode)
            }

            setDialCode(countryPhoneData.dialCode)

            const phoneNumberOrEmpty = phoneIsOnlyCountryCode
                ? ''
                : formatedPhoneNumber

            onChange(phoneNumberOrEmpty, phoneError)
        }
    }

    return (
        <div>
            <PhoneInputWrapper
                containerClass="phone-container"
                inputClass="phone-input"
                buttonClass="phone-button"
                dropdownClass="phone-dropdown"
                searchClass="phone-search"
                {...{
                    country: phoneCountryCode.toLocaleLowerCase(),
                    value: phoneNumber || dialCode,
                    onlyCountries: acceptedCountries,
                    countryCodeEditable: false,
                    onChange: (value, countryPhoneData: CountryPhoneData) =>
                        handleOnChange(value, countryPhoneData),
                    disabled,
                    isValid: phoneIsValid,
                }}
            />
        </div>
    )
}
