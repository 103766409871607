import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

// Theme constants
const BREAKPOINTS = {
    MOBILE: '575px',
    TABLET: '1199px',
    DESKTOP: '1200px',
} as const

const SIZES = {
    NAVBAR_HEIGHT: '140px',
    SIDEBAR_WIDTH: '300px',
    USER_ICON_SIZE: '55px',
    TOGGLE_SIZE: '2rem',
} as const

const TRANSITIONS = {
    DEFAULT: 'all 0.3s linear',
} as const

// Base components with reusable styles
const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`

// Main styled components
export const SideBar = styled.div`
    .collapsing {
        transition: none;
        width: ${SIZES.SIDEBAR_WIDTH};
    }

    @media screen and (min-width: ${BREAKPOINTS.MOBILE}) {
        box-sizing: border-box;
        border: 2px solid ${secondary.whisperWhite};
        border-top: none;
        border-left: none;
        position: relative;

        > * {
            padding: 0;
            height: 100%;
            width: 100%;
            overflow-y: auto;

            > * {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .navbar-nav {
            display: flex;
            flex-direction: column;
        }

        .navbar-brand {
            margin: 0;
            width: 100%;
            padding-top: 0;
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding: 0;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        > * {
            padding: 0;
            width: 100vw;
            z-index: 500;
            position: fixed;

            > * {
                background-color: white;
                position: relative;
                height: 100%;

                &:nth-child(2) {
                    overflow-y: auto;
                    height: 100vh;

                    > *:nth-child(2) {
                        min-height: 600px;
                    }
                }
            }
        }
    }
`

export const TopBar = styled(FlexContainer)`
    height: ${SIZES.NAVBAR_HEIGHT};
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
    border-radius: 0 0 2px 2px;
    background-color: ${primary.darkBlue};
    border-right: none;

    > * {
        width: 200px;
        margin: 45px;
    }
`

export const NavRow = styled.div`
    width: 100%;
    margin-top: -5px;

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
        margin-top: -30px;
    }

    > div > a {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        position: relative;

        button {
            display: flex;
            height: 3em;
            padding: 1em 15px;
            margin: 5px 20px;
            position: relative;
            background-color: transparent;
            color: ${primary.lightGray};
            font-size: 1.2em;
            border: none;
            border-radius: 8px;
            align-items: flex-start;
            transition: ${TRANSITIONS.DEFAULT};

            > * {
                position: absolute;
                left: 2.5em;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 500;
                color: ${primary.darkBlue};
                pointer-events: none;
            }

            &.active:not(.subItemNavButton):not(.no-active-bg) {
                background-color: ${secondary.paleBlue};
            }

            &:hover:not(.subItemNavButton) {
                background-color: ${secondary.whisperWhite};
            }
        }
    }

    .nav-sub-items {
        margin-left: 50px;
        margin-top: -20px;

        button {
            padding: 0 15px !important;
            height: 2em !important;
            &.active {
                background-color: none !important;
                text-decoration: underline;
            }
        }

        .navButton {
            font-size: 0.7em;
            width: 80%;
            text-align: left !important;
        }
    }
`

export const ButtonText = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledToggle = styled.button<{ open: boolean }>`
    width: ${SIZES.TOGGLE_SIZE};
    height: ${SIZES.TOGGLE_SIZE};
    position: fixed;
    top: 15px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP}) {
        display: none;
    }

    @media (max-width: ${BREAKPOINTS.TABLET}) {
        left: 0;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
        right: 10px;
    }

    div {
        width: ${SIZES.TOGGLE_SIZE};
        height: 0.25rem;
        background-color: ${({ open }) =>
            open ? secondary.lightGray : primary.darkBlue};
        border-radius: 10px;
        transform-origin: 1.8px;
        transition: ${TRANSITIONS.DEFAULT};

        &:nth-child(1) {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            transform: ${({ open }) =>
                open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }

        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`
