import { Accordion } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

interface ColumnProps {
    numberOfColumns: number
    rightJustifiedColumns?: number[]
}

interface EmptyListProps {
    insideModal?: boolean
}

export const HeaderRow = styled.div<ColumnProps>`
    padding: 20px;
    padding-right: 40px;
    color: ${primary.gray};
    font-size: 0.8rem;
    margin-bottom: -0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: ${({ numberOfColumns }) =>
        `repeat(${numberOfColumns}, 1fr)`};

    ${({ rightJustifiedColumns }) =>
        rightJustifiedColumns &&
        rightJustifiedColumns.map(
            (n: number) => `
            > :nth-child(${n}) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: right;
            }
        `,
        )}

    .centered {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
`

export const BadgeRow = styled.div<ColumnProps>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ numberOfColumns }) =>
        `repeat(${numberOfColumns}, 1fr)`};
    > a {
        width: fit-content;
        text-decoration: none;
        :hover {
            text-decoration: underline;
        }
    }

    ${({ rightJustifiedColumns }) =>
        rightJustifiedColumns &&
        rightJustifiedColumns.map(
            (n: number) => `
                > :nth-child(${n}) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    text-align: right;
                }
            `,
        )}

    .centered {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;

        > span {
            place-self: center;
            text-overflow: elipsis;
            max-width: 240px;
        }
    }

    @media screen and (max-width: 1620px) {
        gap: 10px;
        > span {
            display: grid;
            grid-template-columns: none;
            flex-direction: row;
        }

        > div {
            :last-child {
                width: 24px;
                justify-self: end;
            }
        }
    }

    > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 400px;
        align-self: center;
    }
`

export const AccordionBody = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    color: ${primary.darkBlue};

    justify-content: space-between;
    > *:not(.kebab) {
        > :first-child {
            color: ${primary.gray};
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
        }

        > .centered {
            text-align: center;
        }
    }
    > :first-child {
        text-align: left;
    }
    > [role='button'] {
        align-self: center;
        font-size: 1.5rem;
    }

    > * {
        display: flex;
        flex-direction: column;
    }
`

export const AccordionWrapper = styled(Accordion)`
    overflow: auto;
    height: 75vh;
`

export const EmptyListIconWrapper = styled.p<EmptyListProps>`
    font-size: 5rem;
    text-align: center;
    color: ${secondary.lightGray};

    ${({ insideModal }) =>
        insideModal && `font-size: 2rem; margin: 0; padding: 0;`}
`

export const PageLine = styled.div`
    border-bottom: 2px solid ${secondary.whisperWhite};
    width: 100%;
    margin-bottom: 1rem;
`
export const NoLicensesInfoIconWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;

    > p {
        text-align: center;
        color: ${primary.blue};
        font-size: 1.5rem;
    }
`
