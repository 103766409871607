import { createAsyncThunk } from '@reduxjs/toolkit'
import getSupportedCountryData from 'api/countries/getSupportedCountryData'

const loadCountryData = createAsyncThunk(
    'countryData/loadCountryData',
    async () => {
        return getSupportedCountryData()
    },
)

export default loadCountryData
