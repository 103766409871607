import React, { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { postPasswordReset } from 'api/users'
import { PasswordInputField } from 'apps/shared/components/FormInputFields'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { SharlicLogo } from 'apps/shared/components/Logo'
import { FormLabel } from 'apps/shared/shared.styled'
import useToast from 'hooks/useToast'

import { Banner, LoginForm } from '../Login/Login.styled'
import {
    PasswordInputWrapper,
    ResetSuccessWrapper,
} from '../Recover/Recover.styled'

export default function ResetPage() {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { errorToast } = useToast()
    const [passwordError, setPasswordError] = useState('')
    const [canSubmit, setCanSubmit] = useState(false)
    const { uid, token, new_user: newUser } = useParams()

    useEffect(() => {
        const passwordsMatch =
            password1 && password2 && password1 !== password2
                ? 'Passwords do not match'
                : ''
        setPasswordError(passwordsMatch)

        const hasErrors =
            password1 && password2 && passwordsMatch === '' && !submitAttempted

        setCanSubmit(!!hasErrors)
    }, [password1, password2, submitAttempted])

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if (!uid || !token) return

        const params = {
            new_password1: password1,
            new_password2: password2,
            uid,
            token,
        }

        postPasswordReset(params)
            .then((response) => {
                if (response.status === 200) {
                    setSubmitted(true)
                }
            })
            .catch(() => {
                errorToast('There was an error resetting your password')
                setSubmitAttempted(true)
            })
    }

    const renderLogoSection = () => {
        const initalHeader = !newUser ? 'Reset Password' : 'Welcome to Sharlic'
        const header = !submitted ? initalHeader : 'Password update successful'

        return (
            <Banner>
                <SharlicLogo />
                <h2>{header}</h2>
            </Banner>
        )
    }

    const handleSetPassword = (
        value: string,
        setPassword: (value: string) => void,
    ) => {
        setPassword(value)
        setSubmitAttempted(false)
    }

    const renderPasswordInput = () => {
        return (
            <>
                <Form onSubmit={handleSubmit}>
                    <PasswordInputWrapper>
                        <FormLabel>New password:</FormLabel>
                        <PasswordInputField
                            value={password1}
                            onChange={(value) =>
                                handleSetPassword(value, setPassword1)
                            }
                            isInvalid={!!passwordError}
                        />
                        <FormLabel>Confirm password:</FormLabel>
                        <PasswordInputField
                            value={password2}
                            onChange={(value) =>
                                handleSetPassword(value, setPassword2)
                            }
                            isInvalid={!!passwordError}
                            invalidFeedback={passwordError}
                        />
                        <Button type="submit" disabled={!canSubmit}>
                            Submit
                        </Button>
                    </PasswordInputWrapper>
                </Form>
                <a href="/" id="footer">
                    Return to Log in Page
                </a>
            </>
        )
    }

    const renderSuccessMessage = () => {
        return (
            <ResetSuccessWrapper>
                <p>You can now log in with your new password.</p>
                <a href="/" id="footer">
                    Return to Log in Page
                </a>
            </ResetSuccessWrapper>
        )
    }

    const renderResetContent = () => {
        if (submitted) {
            return renderSuccessMessage()
        }

        return renderPasswordInput()
    }

    if (!uid || !token) {
        return <LoadingSpinner />
    }

    return (
        <LoginForm>
            <Container>
                <div className="login-component">
                    {renderLogoSection()}
                    {renderResetContent()}
                </div>
            </Container>
        </LoginForm>
    )
}
