import styled from 'styled-components'
import { primary } from 'utils/colors'

export const PdfButtonWrapper = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0;
    color: ${primary.blue};
    gap: 10px;

    > i {
        color: ${primary.darkRed};
    }

    &:hover {
        text-decoration: underline;
        color: ${primary.lightBlue};

        > i {
            color: ${primary.red};
        }
    }
`
