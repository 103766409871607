import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import OtpInput from 'react-otp-input'

import { OtpInputField } from './OneTimeTokenField.styled'

interface OneTimeTokenFieldProps {
    onChange: (otp: string) => void
}

export default function OneTimeTokenField(
    defaultProps: OneTimeTokenFieldProps,
) {
    const { onChange } = defaultProps
    const [otp, setOtp] = useState('')

    const handleSetOtp = (code: string) => {
        setOtp(code)
        onChange(code)
    }

    return (
        <OtpInputField>
            <OtpInput
                value={otp}
                onChange={handleSetOtp}
                numInputs={6}
                containerStyle={{
                    justifyContent: 'center',
                    justifySelf: 'center',
                    gap: '10px',
                }}
                inputStyle={{
                    width: '3rem',
                    height: '3rem',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                }}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
            />
        </OtpInputField>
    )
}
